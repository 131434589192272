import React, { useState, useEffect, memo, useContext } from 'react';
import ReadMore from 'react-native-read-more-text'
import { View, StyleSheet, Text } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { MAIN_COLOR, OFF_BLACK } from '../constants';
import MontSBold from '../components/MontserratBold';
import InterReg from '../components/InterReg';
import Tabs from '../components/Tabs';
import AlsoOwnBook from '../components/AlsoOwnBook';
import TitleLine from '../components/TitleLine';
import BookReviews from '../components/BookReviews';
import FetchError from '../components/FetchError';
import BookHeader, { UPPER_Y_SCROLL, COVER_HEIGHT, COVER_OVERLAP } from '../components/BookHeader';
import Animated, { useSharedValue, useAnimatedScrollHandler, scrollTo, useAnimatedRef } from 'react-native-reanimated';
import { getBookApi } from '../actions/getBook';
import { shouldAnimateBookHeader, getKeywordLabel } from '../utils';
import { useSetInitialPath, useGetDataOnNavFocus, useBook, useBookKeywords, useGetBookDone, useOpenPageLoadedAction } from '../hooks';
import AppSettingsContext from '../context/AppSettingsContext';

const tBase = 'screens.book';

export default function BookScreen({ navigation, route }) {
    const bookId = route?.params?.bookId;
    const book = useBook(bookId);
    const dispatch = useDispatch();
    const [bookFetchStatus, done] = useGetBookDone(bookId);
    const getBook = () => { dispatch(getBookApi({ bookId, showLoading: !book })).then(done); }
    useGetDataOnNavFocus(navigation, getBook);
    if(!bookFetchStatus && !book) return <View />;
    if(['failed', 'notFound', 'notAllowed'].includes(bookFetchStatus)) {
        return (
            <FetchError 
                tBase={ tBase }
                navigation={navigation} 
                status={ bookFetchStatus } />
        )
    }
    if(!book) return <View />;
    return (
        <Book 
            book={book} 
            route={route} 
            navigation={navigation} />
    )
}

const SNAP_THRESHOLD = 40;
const TABS = ['reviews', 'infos'];

const Book = memo(({ book, route, navigation }) => {
    const [scrollHeight, setScrollHeight] = useState(null)
    const [scrollContentHeight, setScrollContentHeight] = useState(null);
    const [bookContentwidth, setBookContentwidth] = useState(500);
    const keywords = useBookKeywords(book.id);
    const insets = useSafeAreaInsets();
    const animateHeader = useSharedValue(0);
    const scrollRef = useAnimatedRef();
    const scrollY = useSharedValue(0);
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const showNotes = loggedUserId === book.ownerId && !!book.note;
    const onLayoutScrollView = ({ nativeEvent: { layout: { height }}}) => !scrollHeight && setScrollHeight(height);
    const onLayoutBookContent = ({ nativeEvent: { layout: { width }}}) => bookContentwidth != width && setBookContentwidth(width);
    const scrollHandler = useAnimatedScrollHandler({
        onScroll: (e) => {
            scrollY.value = e.contentOffset.y;
        },
        onBeginDrag: (e, ctx) => {
            ctx.y = e.contentOffset.y;
        },
        onEndDrag: (e, ctx) => {
            if(e.velocity.y == 0) {
                const scrollingUp = ctx.y < e.contentOffset.y;
                const scrollingDown = ctx.y > e.contentOffset.y;
                if((scrollingUp && e.contentOffset.y > SNAP_THRESHOLD) || (scrollingDown && e.contentOffset.y >= UPPER_Y_SCROLL)) {
                    if(e.contentOffset.y < UPPER_Y_SCROLL) {
                        scrollTo(scrollRef, 0, UPPER_Y_SCROLL, true);
                    }
                } else if ((scrollingDown && e.contentOffset.y < UPPER_Y_SCROLL) || (scrollingUp && e.contentOffset.y <= SNAP_THRESHOLD)) {
                    scrollTo(scrollRef, 0, 0, true);
                }
            }
        },
        onMomentumEnd: (e, ctx) => {
            const scrollingUp = ctx.y < e.contentOffset.y;
            const scrollingDown = ctx.y > e.contentOffset.y;
            if((scrollingUp && e.contentOffset.y > SNAP_THRESHOLD) || (scrollingDown && e.contentOffset.y >= UPPER_Y_SCROLL)) {
                if(e.contentOffset.y < UPPER_Y_SCROLL) {
                    scrollTo(scrollRef, 0, UPPER_Y_SCROLL, true);
                }
            } else if ((scrollingDown && e.contentOffset.y < UPPER_Y_SCROLL) || (scrollingUp && e.contentOffset.y <= SNAP_THRESHOLD)) {
                scrollTo(scrollRef, 0, 0, true);
            }
        }
    })
    useEffect(() => {
        if(book?.title) navigation.setOptions({ title: book?.title })
    }, [])
    function onContentSizeChange(_, contentHeight) {
        if(scrollContentHeight == 0 || scrollContentHeight == null || scrollContentHeight != Math.round(contentHeight)) {
            setScrollContentHeight(Math.round(contentHeight));
        }
    }
    useEffect(() => {
        animateHeader.value = shouldAnimateBookHeader(scrollHeight, scrollContentHeight, UPPER_Y_SCROLL) ? 
            1 : 0;
    }, [scrollContentHeight]);
    useSetInitialPath(route);
    useOpenPageLoadedAction(route, navigation, 'book')
    return (
        <View style={{ flex: 1 }}>
            <BookHeader 
                navigation={navigation} 
                route={route} 
                book={book} 
                scrollY={scrollY}
                animateHeader={animateHeader} />
            <Animated.ScrollView
                ref={scrollRef}
                scrollEventThrottle={16}
                onScroll={scrollHandler} 
                onLayout={onLayoutScrollView}
                style={{marginTop: -COVER_HEIGHT}}
                onContentSizeChange={onContentSizeChange}
                contentContainerStyle={{ paddingTop: COVER_HEIGHT-COVER_OVERLAP, paddingBottom: insets.bottom, alignItems: 'center' }}>
                <View style={{ width: '100%', maxWidth: 900, alignSelf: 'center', paddingHorizontal: 20 }} onLayout={onLayoutBookContent}>
                    <MainInfo style={{marginTop: 15}} title={book.title} subTitle={book.subTitle} author={book.author} />
                    <Description description={book.description } />
                    <Keywords keywords={keywords} />
                    { showNotes && <Notes note={book.note} /> }
                    <Toggle book={book} navigation={navigation} bookContentwidth={bookContentwidth} />
                </View>
                <AlsoOwnBook bookId={book.id} bookOwnerId={book.ownerId} navigation={navigation} />
            </Animated.ScrollView>
        </View>
    )
})

const Toggle = memo(({ book, navigation, bookContentwidth }) => {
    const [tab, setTab] = useState(TABS[0]);
    return (
        <>
            <Tabs tabs={TABS} style={{ marginBottom: 20 }} tBase={tBase} activeTab={tab} onTabPress={setTab} />
            { tab == 'reviews' ? 
                <BookReviews
                    tBase={tBase}
                    navigation={navigation} 
                    providerBookId={book.providerBookId}
                    bookId={book.id} /> : 
                <SecondaryInfo 
                    publisher={book.publisher}
                    year={book.year}
                    month={book.month}
                    day={book.day}
                    isbn={book.isbn}
                    pageCount={book.pageCount}
                    lang={book.lang}
                    width={bookContentwidth}
                    collection={book.collection}
                    collectionNbr={book.collectionNbr}
                    code={book.code} /> 
            }
        </>
    )
})

const MainInfo = memo(({style, textAlign='center', title, subTitle, author, numberOfLines={}}) => (
    <View style={style}>
        <MontSBold text={title} numberOfLines={numberOfLines.title} style={[ styles.bookTitle, { textAlign, marginBottom: subTitle ? 3 : 5 } ]} />
        { subTitle ? <InterReg numberOfLines={numberOfLines.subTitle} text={subTitle} style={[ styles.bookSubTitle, { textAlign } ]} /> : null }
        { author ? <InterReg numberOfLines={numberOfLines.author} text={author} style={[ styles.bookAuthor, { textAlign } ]} /> : null }
    </View>
))

const Notes = memo(({note}) => {
    const {t} = useContext(AppSettingsContext);
    return (
        <View style={{ marginBottom: 15 }}>
            <TitleLine style={{ marginTop: 15 }} title={t(`${tBase}.notes`)} />
            <InterReg text={note} />
        </View>
    )
})

const SecondaryInfo = memo(({ publisher, year, month, day, isbn, pageCount, lang, collection, collectionNbr, code, width }) => {
    const {t} = useContext(AppSettingsContext);
    const dateDePub = `${day || ''}${day && month ? '/' : ''}${month || ''}${month && year ? '/' : ''}${year || ''}`;
    let collectionValue = collection ? collection : '';
    collectionValue += collectionValue && collectionNbr ? ` - n°${collectionNbr}` : '';
    const infoGroups = [
        [
            { label: t(`${tBase}.info.publisher`), value: publisher || '-' }, 
            { label: t(`${tBase}.info.publicationDate`), value: dateDePub || '-' }
        ],
        [
            { label: t(`${tBase}.info.isbn`), value: isbn || '-' }, 
            { label: t(`${tBase}.info.numberOfPages`), value: pageCount || '-' }
        ],
        [
            { label: t(`${tBase}.info.language`), value: lang ? lang.toUpperCase() : '-' },
            { label: t(`${tBase}.info.collection`), value: collectionValue || '-' }
        ]
    ]
    if(code) {
        infoGroups.push([
            { label: t(`${tBase}.info.code`), value: code || '-' },
            { label: '', value: '' }
        ])
    }
    const marginBottom  = width > 700 ? 20 : 13
    const flexDirection = width > 700 ? 'row': 'column';
    return (
        <View style={{ flex: 1, alignSelf: 'stretch' }}>
            { infoGroups.map((infoGroup, index) => (
                <View key={index} style={{ alignSelf: 'stretch', flexDirection }}>
                    { infoGroup.map(({label, value}, index) => {
                        const marginRight = width > 700 && index == 0 ? 40 : 0
                        return (
                            <View key={label} style={[styles.infoGroup, { marginBottom, marginRight }]}>
                                <InterReg style={styles.infoLabel} text={ label + (label ? ' : ' : '') } />
                                <MontSBold style={styles.infoValue} text={ value } />
                            </View>
                        )
                    })}
                </View>
            ))}
        </View>
    )
})

const Description = memo(({numberOfLines=3, layoutType='small', description}) => {
    const {t} = useContext(AppSettingsContext);
    const renderTruncatedFooter = (handlePress) => <Text style={ styles.toggleText } onPress={handlePress}>{ t('general.viewMore') }</Text>;
    const renderRevealedFooter = (handlePress) => <Text style={ styles.toggleText } onPress={handlePress}>{ t('general.viewLess') }</Text>;
    return (
        <View style={{ alignSelf: 'stretch', marginTop: 22 }}>
            <TitleLine title={t(`${tBase}.description`)} />
            <ReadMore
                numberOfLines={numberOfLines}
                renderTruncatedFooter={renderTruncatedFooter}
                renderRevealedFooter={renderRevealedFooter}>
                <InterReg 
                    text={ description || t(`${tBase}.noDescription`) } 
                    style={[ 
                        styles.description, 
                        { fontSize: layoutType == 'small' ? 14 : 15, lineHeight: layoutType == 'small' ? 19 : 21 } 
                    ]} />
            </ReadMore>
        </View>
    )
})

const Keywords = memo(({keywords}) => {
    const {locale} = useContext(AppSettingsContext);
    if(!keywords.length) return null;
    return (
        <View style={ styles.keywords }>
            { keywords.map(keyword => (
                <View style={ styles.keyword } key={ keyword.id }>
                    <InterReg style={{ fontSize: 12, color: '#343434'}} text={getKeywordLabel(keyword, locale).toUpperCase()} />
                </View>
            ))}
        </View>
    ) 
})

const styles = StyleSheet.create({
    bookTitle: {
        fontSize: 17,
        textAlign: 'center',
        marginBottom: 5,
        color: 'rgba(0,0,0,0.9)'
    },
    bookSubTitle: {
        fontSize: 15,
        textAlign: 'center',
        marginBottom: 5,
        color: 'rgba(0,0,0,0.8)'
    },
    bookAuthor: {
        fontSize: 15,
        color: '#666',
        textAlign: 'center',
        color: 'rgba(0,0,0,0.6)'
    },
    infoGroup: {
        flex: 1, 
        flexDirection: 'row'
    },
    infoLabel: { 
        color: '#606060' 
    },
    infoValue: { 
        flex:1, 
        textAlign: 'right'
    },
    toggleText: {
        color: MAIN_COLOR,
        marginTop: 5
    },
    description: {
        fontFamily: 'Inter_400Regular', 
        color: OFF_BLACK, 
        lineHeight: 19        
    },
    keywords: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignSelf: 'stretch',
        alignItems: 'flex-start',
        marginTop: 15
    },
    keyword: {
        borderColor: '#d7d7d7', 
        borderWidth: 1, 
        borderRadius: 3, 
        paddingHorizontal: 6, 
        paddingVertical: 2,
        marginRight: 8,
        marginBottom: 8
    },
})