export default {
    alerts: {
        acceptBorrowRequest: {
            title: "Accept borrow request",
            subTitle: "Once accepted you can confirm that the book was exchanged."
        },
        acceptGroupRequest: {
            title: "Accept request to join group",
            subTitle: "By accepting '%{nickname}' will become a member of '%{name}'."
        },
        acceptRequestFollowUser: {
            title: "Accept follow request",
            subTitle: "Accepting will allow %{nickname} to view your shelves and/or lists visible to your followers."
        },
        actions: {
            accept: "Accept",
            add: "Add",
            addManually: "Add manually",
            cancel: "Cancel",
            cancelRequest: "Cancel request",
            delete: "Delete",
            exchanged: "Exchanged",
            fillProfile: "Fill profile",
            leave: "Leave",
            modify: "Modify",
            refuse: "Refuse",
            remove: "Remove",
            request: "Request",
            return: "Return",
            sendRequest: "Send request"
        },
        addExistingBook: {
            title: "You have already added this book",
            subTitle: "Do you want to add it anyway?"
        },
        allowAccessToContacts: {
            title: "We weren't able to access your contacts",
            subTitle: "You need to allow MyBlio access to use this feature."
        },
        askForFeedback: {
            title: "Enjoying this app?",
            subTitle: "We'd like to know if you like the app. Your feedback will help us to make a difference!",
            ok: "😕 It'ok",
            great: "😍 Love it"
        },
        bookBorrowedFrom: {
            title: "Was book borrowed by %{username}?",
            subTitle: "Once the exchange is confirmed it will be visible in the borrowing tab."
        },
        bookLentTo: {
            title: "Was book lent to %{username}?",
            subTitle: "Once the exchange is confirmed it will be visible in the lending tab."
        },
        cancelBorrowRequest: {
            title: "Cancel borrow request",
            subTitle: ""
        },
        cancelGroupSubscription: {
            title: "Cancel this subscription?",
            subTitle: "You are about to cancel '%{plan}' for the group '%{groupName}'.",
            cancelSubscription: "Cancel subscription"
        },
        deleteAccount: {
            title: "Delete your account",
            subTitle: "This action can't be undone."
        },
        deleteBook: {
            title: "Delete this book",
            subTitle: "This action can't be undone."
        },
        deleteBookReview: {
            title: "Delete your review of this book",
            subTitle: "This action can't be undone."
        },
        deleteGroup: {
            title: "Delete this group",
            subTitle: "Beware '%{name}' will be permanently deleted."
        },
        deleteGroupCollection: {
            title: "Delete this group collection",
            subTitle: "Beware '%{name}' will be permanently deleted."
        },
        deleteList: {
            title: "Delete this list",
            subTitle: "This action can't be undone."
        },
        deleteShelf: {
            title: "Delete this shelf",
            subTitle: "Beware the books it contains will also be delete."
        },
        deleteUserKeyword: {
            title: "Delete this keyword",
            subTitle: "This action can't be undone."
        },
        emailSmtpError: {
            title: "⚠️ Email address error",
            subTitle: "Please ensure that '%{email}' is your correct email address."
        },
        joinGroup: {
            title: "Request to join group",
            subTitle: "The admin of '%{name}' will be notified of your request."
        },
        leaveGroup: {
            title: "Leave this group",
            subTitle: "You will no longer be a member of '%{name}' and your shared books will be removed."
        },
        noResultFromScannedIsbn: {
            title: "Sorry! No book was found",
            subTitle: "You can always add it manually."
        },
        profileMustBeFilledToRequestBook: {
            title: "Just one thing first!",
            subTitle: "Your profile must be complete in order to send a borrow request."
        },
        promptLoginOrSignup: {
            title: "MyBlio account required",
            login: "Login",
            or: "or",
            signup: "Create an account",
            subTitle: "Create an account or login to an existing one to proceed."
        },
        refuseBorrowRequest: {
            title: "Refuse borrow request",
            subTitle: ""
        },
        refuseGroupRequest: {
            title: "Refuse request to join group",
            subTitle: "Request by %{nickname}'s to join %{name}."
        },
        refuseRequestFollowUser: {
            title: "Refuse follow request",
            subTitle: "%{nickname} won't be alerted of your refusal."
        },
        requestReturnBook: {
            title: "Request the return of your book?",
            subTitle: "%{nickname} will be notified of your wish to recover \"%{bookTitle}\"."
        },
        removeFromAllLists: {
            title: "Remove from all lists?",
            subTitle: "This means '%{title}' will be removed from your library."
        },
        removeFromList: {
            title: "Remove book from list",
            subTitle: ""
        },
        removeMemberFromGroup: {
            title: "Remove member from group",
            subTitle: "%{nickname} will be removed and the books they shared."
        },
        requestFollowUser: {
            title: "Send follow request?",
            subTitle: "%{nickname}'s profile is private. You can access it once your request is accepted."
        },
        returnBook: {
            title: "Set book as returned",
            subTitle: ""
        },
        returnBookNyBorrower: {
            title: "Indicate that you've returned this book",
            subTitle: "The books owner will be notified and can confirm the return."
        },
        sendBorrowRequest: {
            title: "Send request to borrow",
            from: "from"
        }
    },
    book: {
        add: "Add a book",
        borrow: "Borrow",
        borrowedFrom: "Borrowed from",
        borrowingFrom: "Borrowed from",
        lending: "Lending",
        lendingTo: "Lent to",
        lentTo: "Lent to",
        notLending: "Not lending",
        notPossessed: "Not owned",
        onThe: "on the",
        possessed: "Owned",
        requestAcceptedBy: "Request accepted by",
        requestedBy: "by",
        requestedFrom: "from",
        requestedOnThe: "Requested on the",
        returned: "Returned on the",
        returnRequested: "Return requested",
        returnIndicatedByBorrower: "Return indicated by %{nickname}",
        returnBy: {
            one: "Book due to be returned tomorrow",
            other: "Book due to be returned in %{count} days",
            zero: "Book due to be returned today"
        },
        returnByOverDue: {
            one: "Book is overdue by 1 day",
            other: "Book is overdue by %{count} days"
        },
        sort: {
            authorAZ: "Author A-Z",
            authorZA: "Author Z-A",
            newest: "Newly added first",
            oldest: "Newly added last",
            titleAZ: "Title A-Z",
            titleZA: "Title Z-A",
            collectionAZ: "Collection A-Z",
            collectionZA: "Collection Z-A"
        },
        status: {
            own: "Own",
            read: "Read",
            reading: "Reading",
            toRead: "To read",
            unread: "Unread"
        },
        viewAll: "View all books",
        youAcceptedRequestBy: "You accepted request by",
        filters: {
            title: "Filters",
            readingStatus: "Reading status",
            ownership: "Ownership",
            lending: "Lending status",
            keywords: "Keywords",
            clearAll: "Clear all"
        }
    },
    date: {
        formats: {
            long: "%-d %B %Y",
            messageLong: "%a %-d %b at %-I:%M%P",
            messageNoYear: "%d %b",
            messageTime: "%-I:%M%P",
            messageWithYear: "%d %b %Y",
            messageYesterday: "Yesterday at %-I:%M%P",
            short: "%d/%m/%y",
            yesterday: "Yesterday"
        },
        since: {
            daysAgo: {
                one: "yesterday",
                other: "%{count} days ago",
                zero: "%{count} days ago"
            },
            hoursAgo: {
                one: "1 hour ago",
                other: "%{count} hours ago",
                zero: "%{count} hours ago"
            },
            minutesAgo: {
                one: "1 minute ago",
                other: "%{count} minutes ago",
                zero: "%{count} minutes ago"
            },
            momentsAgo: "moments ago"
        },
        units: {
            days: {
                one: "day",
                other: "days",
                zero: "days"
            },
            hours: {
                one: "hour",
                other: "hours",
                zero: "hours"
            },
            minutes: {
                one: "minute",
                other: "minutes",
                zero: "minutes"
            }
        }
    },
    datetime: {
        distance_in_words: {
            about_x_hours: {
                one: "about 1 hour",
                other: "about %{count} hours"
            },
            about_x_months: {
                one: "about 1 month",
                other: "about %{count} months"
            },
            about_x_years: {
                one: "about 1 year",
                other: "about %{count} years"
            },
            almost_x_years: {
                one: "almost 1 year",
                other: "almost %{count} years"
            },
            half_a_minute: "half a minute",
            less_than_x_minutes: {
                one: "less than a minute",
                other: "less than %{count} minutes"
            },
            less_than_x_seconds: {
                one: "less than 1 second",
                other: "less than %{count} seconds"
            },
            over_x_years: {
                one: "over 1 year",
                other: "over %{count} years"
            },
            x_days: {
                one: "1 day",
                other: "%{count} days"
            },
            x_minutes: {
                one: "1 minute",
                other: "%{count} minutes"
            },
            x_months: {
                one: "1 month",
                other: "%{count} months"
            },
            x_seconds: {
                one: "1 second",
                other: "%{count} seconds"
            },
            x_years: {
                one: "1 year",
                other: "%{count} years"
            }
        }
    },
    forms: {
        addBookSearch: {
            errors: {
                queryMin3Chars: "You must use at least 3 characters",
                queryRequired: "You must type a query"
            },
            labels: {
                query: ""
            },
            placeholders: {
                query: "Title, author, ISBN…"
            }
        },
        addBookToLists: {
            labels: {
                listIds: "Choose list(s)"
            },
            placeholders: {
                listIds: "Select one or more lists"
            }
        },
        askForFeedback: {
            placeholders: {
                comments: "Anything else that you would like to tell us about your experience of using MyBlio."
            },
            labels: {
                overallAppScore: "How much do you like the app overall?",
                usabilityScore: "How satisfied are you with the usability of the app?",
                loadingScore: "How satisfied are you with the loading time of the app?",
                featuresScore: "How satisfied are you with the features of the app?",
                comments: "Comments"
            },
            errors: {
                answerRequired: "Question needs an answer",
                commentTooLong: "Comments should be %{limit} characters or less", 
            }
        },
        book: {
            chooseCover: "Choose cover",
            errors: {
                isbnInvalid: "It's not a valid ISBN",
                listIdRequired: "You must choose at least one list",
                shelfRequired: "You must choose a shelf",
                titleRequired: "Title is required",
                yearInFuture: "Year can't be in the future",
                yearMustBe4Digits: "Year must contain 4 digits. (i.e. 2010)",
                yearOnlyDigits: "Year can only contain digits. (i.e. 1234…)",
                monthMaxValue: "Month can't be greater than 12",
                monthMinValue: "Month can't be 0",
                dayMaxValue: "Day can't be greater than 31",
                dayMinValue: "Day can't be 0",
                pageCountTooBig: "Number is too big",
                codeTooLong: "Call number should be %{limit} characters or less"
            },
            labels: {
                title: "Title",
                author: "Author(s)",
                description: "About",
                isbn: "ISBN",
                lang: "Language",
                listIds: "List(s)",
                note: "Notes",
                pageCount: "Number of pages",
                possessed: "I own this book",
                publisher: "Publisher",
                collection: "Collection",
                collectionNbr: "Collection number",
                shelfId: "Shelf",
                subTitle: "Subtitle",
                year: "Year of publication",
                month: "Month of publication",
                day: "Day of publication",
                keywordIds: "Keywords",
                code: "Code"
            },
            modifyCover: "Modify cover",
            placeholders: {
                title: "Title of the book",
                author: "Author #1, author #2",
                description: "What is the book about?",
                isbn: "i.e. 978000000000",
                listIds: "Choose a list",
                note: "Your own personal notes about this book.",
                pageCount: "",
                publisher: "i.e. Penguin",
                collection: "i.e. A Game of Thrones",
                collectionNbr: "i.e 5",
                shelfId: "Select a shelf",
                subTitle: "",
                year: "i.e. 2010",
                month: "i.e. 10",
                day: "i.e. 5",
                keywordIds: "i.e. Novel, Comics…",
                code: "Book's call number"
            },
            sections: {
                bookDetails: "Book details",
                bookPlacement: "Book's placement"
            }
        },
        bookProvider: {
            errors: {
                listIdRequired: "You must choose at least one list",
                shelfRequired: "You must choose a shelf"
            },
            labels: {
                listIds: "Choose list(s)",
                shelfId: "Choose shelf"
            },
            placeholders: {
                listIds: "Select one or more lists",
                shelfId: "Select a shelf"
            }
        },
        bookReview: {
            errors: {
                bodyRequired: "Review is required",
                recommendedRequired: "Recommendation is required",
                reviewTooLong: "Review should be %{limit} characters or less",
                tooFewTags: "You must selected a least 1 descriptor",
                tooManyTags: "You can selected up to %{limit} descriptors"
            },
            labels: {
                body: "Your review",
                recommended: "Would you recommend this book?",
                tags: "Pick best descriptions"
            },
            placeholders: {
                body: "Your thoughts about this book."
            },
            recommend: {
                no: "No",
                yes: "Yes"
            }
        },
        changePassword: {
            errors: {
                confirmPasswordRequired: "You must retype your new password",
                currentPpasswordRequired: "Current password is required",
                newPasswordRequired: "New password is required",
                passwordNotStrong: "Password should have at least 6 characters and contain a number",
                passwordsNotTheSame: "Does not match new password"
            },
            labels: {
                confirmPassword: "Confirm password",
                currentPassword: "Current password",
                newPassword: "New password"
            },
            placeholders: {
                confirmPassword: "Type it again",
                currentPassword: "The password you currently use",
                newPassword: "Your new password"
            },
            submit: "Update password"
        },
        filterGroup: {
            labels: {
                search: ""
            },
            placeholders: {
                search: "Search group"
            }
        },
        group: {
            choosePicture: "Choose image",
            descriptions: {
                public: "If unchecked your group will be private and only its members or people with whom you share its link can vue it."
            },
            errors: {
                descriptionTooLong: "Description should be %{limit} characters or less",
                invalidSlug: "Link is not valid. It should look something like this: my-group-name",
                invalidUrl: "Doesn't appear to valid",
                locationRequired: "Location is required",
                locationTooLong: "Location should be %{limit} characters or less",
                nameRequired: "Name is required",
                nameTooLong: "Name should be %{limit} characters or less",
                slugRequired: "Group's short link is required",
                slugTooLong: "Groups's short link should be %{limit} characters or less",
                usageRequired: "Usage is required"
            },
            labels: {
                description: "Description",
                location: "Location",
                name: "Name",
                public: "Public group",
                slug: "Group's short link",
                usage: "Usage",
                websiteUrl: "Website"
            },
            modifyPicture: "Modify image",
            noPicture: "No picture \rselected",
            placeholders: {
                description: "Say something about what the purpose of this group is.",
                location: "City, town, area, country…",
                name: "",
                slug: "my-group-name",
                usage: "What will this group be used for?",
                websiteUrl: "A related external link"
            }
        },
        groupCollection: {
            errors: {
                descriptionTooLong: "Description should be %{limit} characters or less",
                nameRequired: "Name is required",
                nameTooLong: "Name should be %{limit} characters or less",
                sortRequired: "Sort is required"
            },
            labels: {
                description: "Description",
                name: "Name",
                sort: "Order of books"
            },
            placeholders: {
                description: "",
                name: "Name of the collection",
                sort: "Choose sort"
            }
        },
        groupInvitationByCsv: {
            errors: {
                csvRequired: "Csv file is required"
            },
            labels: {
                emails: "Use a .csv file with emails in the first column"
            },
            buttons: {
                chooseFile: "Choose CSV"
            }
        },
        groupInvitationByEmail: {
            errors: {
                emailNotValid: "Email format not valid",
                emailRequired: "Email is required"
            },
            labels: {
                email: "Email"
            },
            placeholders: {
                email: "Email of person to invite"
            },
            submit: "Send invitation"
        },
        groupShortUrl: {
            labels: {
                url: ""
            }
        },
        inviteUrl: {
            descriptions: {
                url: "Simply share it with the people you wish to invite."
            },
            labels: {
                url: "Using link"
            }
        },
        inviteUserByEmail: {
            descriptions: {
                emailsToInvite: "An invitation will automatically be sent by email."
            },
            errors: {
                emailNotValid: "Email format not valid",
                emailRequired: "Email is required"
            },
            labels: {
                emailsToInvite: "Using email"
            },
            placeholders: {
                emailsToInvite: "Email of person to invite"
            },
            submit: "Send"
        },
        lend: {
            errors: {
                emailNotValid: "Email format not valid",
                emailRequired: "Email is required"
            },
            labels: {
                email: "Email"
            },
            placeholders: {
                email: "Email of person to lend book"
            },
            submit: "Indicate lend"
        },
        list: {
            errors: {
                descriptionTooLong: "Description should be %{limit} characters or less",
                nameRequired: "Name is required",
                nameTooLong: "Name should be %{limit} characters or less",
                sortRequired: "Sort is required",
                visibility: "Visibility is required"
            },
            labels: {
                description: "Description",
                name: "Name",
                sort: "Order of books",
                visibility: "Visibility"
            },
            placeholders: {
                description: "",
                name: "Name of the list",
                sort: "Choose sort",
                visibility: "Choose visibility"
            }
        },
        passwordReset: {
            errors: {
                emailNotValid: "Email format not valid",
                emailRequired: "Email is required"
            },
            labels: {
                email: "Email"
            },
            navigate: {
                signin: "Login"
            },
            placeholders: {
                email: "example@gmail.com"
            },
            submit: "Reset password"
        },
        search: {
            errors: {
                patternMin3Chars: "You must use at least 3 characters",
                patternRequired: "You must type a query"
            },
            labels: {
                pattern: ""
            },
            placeholders: {
                pattern: "Search for book, a user or a group"
            }
        },
        searchBooks: { 
            books: {
                placeholders: {
                    pattern: "Search in your library"
                }
            },
            shelves: {
                placeholders: {
                    pattern: "Search in your shelves"
                }
            },
            lists: {
                placeholders: {
                    pattern: "Search in your lists"
                }
            },
            cancel: "Cancel"
        },
        settings: {
            labels: {
                preferredLang: "Preferred language",
                lendDurationInDays: "Duration of lends",
                sortBooks: "Order of books"
            },
            descriptions: {
                sortBooks: "Allows you to sort the books in your library."
            }
        },
        shelf: {
            errors: {
                nameRequired: "Name is required",
                nameTooLong: "Name should be %{limit} characters or less",
                sortRequired: "Sort is required",
                visibility: "Visibility is required"
            },
            labels: {
                name: "Name",
                sort: "Order of books",
                visibility: "Visibility"
            },
            placeholders: {
                name: "Name of the shelf",
                sort: "Choose sort",
                visibility: "Choose visibility"
            }
        },
        shelfShareBooksGroup: {
            labels: {
                onSiteLocationForGroup: "Location of the books",
                shouldAutoAdd: "Auto add books",
                availableOnSite: "Books available on-site",
                availableSelfService: "Books available for self-service"
            },
            descriptions: {
                shouldAutoAdd: "Any books added to this shelf will be automatically shared in the \"%{groupName}\" group.",
                availableOnSite: "The books of this shelf, shared in the \"%{groupName}\" group, are available on-site.",
                availableSelfService: "The books of this shelf, shared in the \"%{groupName}\" group, can be borrowed autonomously by its members."
            },
            placeholders: {
                onSiteLocationForGroup: "Where are the books located?"
            },
            errors: {
                locationTooLong: "The location should be %{limit} characters or less",
            },
        },
        signin: {
            dontHaveAccount: "I don't have an account!",
            errors: {
                emailNotValid: "Email format not valid",
                emailRequired: "Email is required",
                passwordRequired: "Password is required"
            },
            labels: {
                email: "Email",
                password: "Password"
            },
            navigate: {
                resetPassword: "Forgotten password?",
                signup: "Create one"
            },
            placeholders: {
                email: "example@gmail.com"
            },
            submit: "Login"
        },
        signup: {
            alreadyHaveAccount: "I already have an account!",
            didYouMeanToType: "Did you mean to type",
            questionMark: "?",
            errors: {
                emailNotValid: "Email format not valid",
                emailRequired: "Email is required",
                passwordNotStrong: "Password should have at least 6 characters and contain a number",
                passwordRequired: "Password is required",
                termsMustBeAccepted: "Accept the terms and conditions by pressing the circle"
            },
            labels: {
                email: "Email",
                password: "Password",
                terms: "I accept the terms and conditions"
            },
            navigate: {
                signin: "Login"
            },
            placeholders: {
                email: "example@gmail.com",
                password: "Must contain at least 6 characters"
            },
            submit: "Create account"
        },
        updateAccount: {
            chooseAvatarButton: "Choose your avatar",
            errors: {
                descriptionTooLong: "Description should be %{limit} characters or less",
                emailNotValid: "Email format not valid",
                emailRequired: "Email is required",
                emailSmtp: "There seems to be an error with this email address. Please check that it is correct.",
                locationTooLong: "Location should be %{limit} characters or less",
                nicknameRequired: "Nickname is required",
                nicknameTooLong: "Nickname should be %{limit} characters or less"
            },
            labels: {
                email: "Email",
                description: "Description",
                location: "Location",
                nickname: "Nickname"
            },
            placeholders: {
                email: "example@gmail.com",
                description: "Something about yourself",
                location: "Where are you sharing books from?",
                nickname: "Jane Smith"
            }
        },
        userKeyword: {
            labels: {
                name: "Name"
            },
            placeholders: {
                name: "Keyword name"
            },
            errors: {
                nameRequired: "Name is required",
                nameTooLong: "Name should be %{limit} characters or less",
                nameTooShort: "Name should be at least %{limit} characters"
            }
        }
    },
    general: {
        areFollowing: "Following",
        books: {
            one: "book",
            other: "books",
            zero: "books"
        },
        you: "you",
        follow: "Follow",
        followers: "Followers",
        following: "Following",
        noCover: "No cover",
        requestSent: "Request sent",
        share: "I'm using the collaborative library MyBlio. Sign-up and let's start sharing our books. %{url} ",
        upload: "Upload",
        viewLess: "View less",
        viewMore: "View more",
        yourself: "You"
    },
    group: {
        add: "Create group",
        invitation: {
            accept: "Accept",
            invite: "Invite",
            onThe: "on the ",
            refuse: "Refuse",
            youWereInvited: "You were invited to join this group "
        },
        isAdmin: "Admin",
        isMember: "Member",
        join: "Join",
        request: {
            accept: "Accept",
            cancel: "Cancel request",
            onThe: "on the ",
            refuse: "Refuse",
            userRequestedToJoin: "Requested to join ",
            youRequestedToJoin: "You requested to join this group "
        },
        usage: {
            business: "Professional",
            education: "Education",
            family: "Family",
            nonProfit: "Non-profit",
            other: "Other",
            personal: "Personal",
            religious: "Religious"
        }
    },
    list: {
        add: "Add a list",
        borrowed: {
            description: "Books that you've borrowed using MyBlio are automatically added here. You can also add books that you've borrowed from else where, like a library.",
            name: "Borrowed books"
        },
        lent: {
            description: "Books that you've lent using MyBlio are automatically added here.",
            name: "Lent books"
        },
        possessed: {
            name: "Possessed"
        },
        read: {
            name: "Read"
        },
        reading: {
            name: "Reading"
        },
        toRead: {
            name: "To read"
        },
        unread: {
            name: "Not read"
        },
        visibility: {
            followers: "Followers",
            private: "Private",
            public: "Public"
        },
        wish: {
            description: "Books that you would love to own someday!",
            name: "Wish list"
        }
    },
    menus: {
        add: {
            book: "Add a book",
            list: "Add a list",
            shelf: "Add a shelf"
        },
        book: {
            addRemoveFromList: "Add/remove from list(s)",
            borrow: "Request to borrow",
            cancelBorrowRequest: "Cancel borrow request",
            delete: "Delete",
            hide: "Hide",
            lend: "Lend",
            modify: "Modify",
            removeFromGroup: "Remove from group",
            removeFromList: "Remove from list",
            return: "Return",
            show: "Show"
        },
        bookCover: {
            chooseImg: "Choose an image",
            generate: "Generate cover",
            takePhoto: "Take photo"
        },
        bookLend: {
            contact: "Contact user",
            requestReturn: "Request return",
            viewBook: "View book",
            viewUser: "View user"
        },
        group: {
            header: {
                titles: {
                    books: "Books",
                    members: "Members",
                    collections: "Collections"
                }
            },
            addRemoveBooks: "Add/remove book(s)",
            cancelJoinRequest: "Cancel request to join",
            delete: "Delete group",
            inviteToJoin: "Invite to join group",
            leave: "Leave group",
            modify: "Modify group",
            share: "Share options"
        },
        groupCollection: {
            addRemoveBooks: "Add/remove book(s)",
            delete: "Delete",
            modify: "Modify"
        },
        list: {
            delete: "Delete",
            modify: "Modify"
        },
        shareWeb: {
            email: "Email",
            facebook: "Facebook",
            twitter: "Twitter"
        },
        shelf: {
            delete: "Delete",
            modify: "Modify"
        },
        user: {
            addAdmin: "Make user admin",
            cancelFollowRequest: "Cancel follow request",
            contactUser: "Contact user",
            editYourProfile: "Edit my profile",
            follow: "Follow",
            removeAdmin: "Remove as admin",
            removeFromGroup: "Remove from group",
            requestFollow: "Send follow request",
            unfollow: "Unfollow",
            viewUserProfile: "View user's profile",
            viewYourProfile: "View my profile"
        }
    },
    modal: {
        cta: {
            cancel: "Cancel",
            save: "Save"
        }
    },
    notifs: {
        emailSmtpError: {
            title: "There seems to be an error with your email address",
            description: "Please check that it is correct in Profile > Modify my profile"
        },
        generic: {
            title: "Oops!",
            description: "An error occurred. Please try again later."
        },
        imageNotRecognised: {
            title: "Image type not recognised",
            description: "Try again with another image."
        },
        noCameraAccess: {
            title: "Access to camera refused",
            action: "Modify my settings",
            description: "Please allow MyBlio to access your camera."
        },
        noInternet: {
            title: "Connection error",
            description: "You may not be connected to the internet."
        },
        noPhotoAccess: {
            title: "Access to photos refused",
            description: "Please allow MyBlio to access your photos."
        },
        notAuthorised: {
            title: "Action impossible",
            description: "You are not logged in."
        }
    },
    screens: {
        account: {
            title: "Account",
            changePassword: "Change password",
            deleteAccount: "Delete your account",
            downloadData: "Download your data",
            memberSince: "Member since:",
            subscriptions: "Subscriptions"
        },
        accountProduct: {
            title: "Plan details"
        },
        accountProductCheckoutSuccess: {
            title: "Subscription confirmation",
            subscriptionStarted: "Your subscription is active",
            cancelAnytime: "You can cancel your plan at anytime.",
            productSelected: "Your current active plan is:",
            closeModal: "Close"
        },
        activity: {
            added: "added",
            booksListTitle: {
                one: "%{count} book added by %{name}",
                other: "%{count} books added by %{name}",
                zero: "Books added by %{name}"
            },
            groupCollectionBooksListTitle: {
                one: "%{count} book was added to %{name}",
                other: "%{count} books were added to %{name}",
                zero: "Books added to %{name}"
            },
            earlier: "Earlier",
            empty: {
                title: "No activity",
                addBooks: "Add your books",
                bodyHasBooksAndIsFollowing: "There hasn't been any recent activity. Why not resume reading your current book?",
                bodyHasBooksAndIsNotFollowing: "There hasn't been any recent activity. Why not discover other readers?",
                bodyHasNoBooks: "There hasn't been any recent activity. Perhaps start by adding books to your library.",
                searchUsers: "Find other readers"
            },
            finishedReading: "finished reading this book",
            followsYou: "started following you",
            joinedGroup: "joined the group",
            postedReview: "posted a review of this book",
            startedReading: "started reading this book",
            thisMonth: "This month",
            thisWeek: "This week",
            toTheGroup: "to the group",
            toTheCollection: "added to the collection",
            inGroup: "in",
            today: "Today",
            viewAllBooks: "View all"
        },
        addBook: {
            title: "Manually add book",
            submit: "Add"
        },
        addBookReview: {
            title: "Post a review",
            submit: "Post review"
        },
        addBookSearch: {
            title: "Search by keyword",
            addManually: "Add manually",
            bookAdded: "Added",
            noResults: {
                title: "No books found 😢",
                body: "You can always add it manually 😉",
                addManually: "Add book manually"
            },
            noSearch: {
                title: "What book are you looking for?",
                body: "Find the books to complete your library."
            },
            or: "Or"
        },
        addGroup: {
            title: "Create a group",
            submit: "Create",
            actionProduct: "modify"
        },
        addGroupCollection: {
            title: "Create a group collection",
            submit: "Create"
        },
        addList: {
            title: "Add list",
            submit: "Add list"
        },
        addShelf: {
            title: "Add shelf",
            submit: "Add shelf"
        },
        addUserKeyword: {
            title: "Add keyword",
            submit: "Add keyword"
        },
        askForFeedback: {
            title: "Your feedback",
            submit: "Send"
        },
        book: {
            otherOwners: {
                ownThisBook: "Own this book",
                alsoOwnBook: "Also own this book",
                viewUser: "View"
            },
            description: "Description",
            failed: {
                title: "⚠️ Book error",
                body: "Could not retrieve book. Try again later.",
                goBack: "Return to previous page",
                goHome: "Go to home page"
            },
            info: {
                collection: "Collection",
                isbn: "ISBN",
                language: "Language",
                numberOfPages: "Number of pages",
                publicationDate: "Publication date",
                publisher: "Publisher",
                code: "Call number"
            },
            modifyReviewBtn: "Modify",
            nextReviewBtn: "Next",
            noDescription: "No description available",
            noReviewsYet: "This book has no reviews currently.",
            notAllowed: {
                title: "✋ Not permitted",
                body: "Sorry! You're not allowed to view this book.",
                goBack: "Return to previous page",
                goHome: "Go to home page"
            },
            notFound: {
                title: "Book not found",
                body: "It may have been deleted or the link is incorrect.",
                goBack: "Return to previous page",
                goHome: "Go to home page"
            },
            notes: "Your notes",
            
            previousReviewBtn: "Previous",
            tab: {
                label: {
                    infos: "Details",
                    reviews: "Reviews"
                }
            },
            writeReviewBtn: "Write a review"
        },
        bookInLists: {
            title: "Add or remove from list(s)",
            createListButton: "Create a new list"
        },
        bookProviderResult: {
            title: "Book details",
            submit: "Add book"
        },
        booksInGroup: {
            title: "Add/remove books",
            submit: "Save"
        },
        booksInGroupCollection: {
            title: "Add/remove books",
            submit: "Save"
        },
        books: {
            empty: {
                title: "Your library is empty",
                body: "Start adding some books, it's quick and easy.",
                addBooks: "Add your books"
            },
            noResults: {
                title: "No books found in your library",
                patternAndFilterBody: "Using the search term \"%{pattern}\" and filter(s).",
                filterBody: "Using the current filter(s).",
                patternBody: "Using the search term \"%{pattern}\".",
                showAll: "Show all"
            }
        },
        borrowRequest: {
            acceptRequest: "Accept",
            cancelRequest: "Cancel request",
            empty: {
                accepted: {
                    title: "No accepted book requests",
                    body: "This is where accepted requests to borrow books will appear."
                },
                all: {
                    title: "No book requests",
                    body: "This is where requests to borrow books will appear."
                },
                requestByMe: {
                    title: "No book requests by you",
                    body: "This is where your requests to borrow books will appear."
                },
                requestToMe: {
                    title: "No book requests from others",
                    body: "This is where requests to borrow your books will appear."
                }
            },
            filter: {
                accepted: "Accepted",
                all: "All",
                requestByMe: "Mine",
                requestToMe: "Others"
            },
            finaliseLend: "Confirm exchange",
            refuseRequest: "Refuse"
        },
        borrowing: {
            contactUser: "Contact user",
            indicateReturnedBook: "Indicate return",
            empty: {
                current: {
                    title: "No books being borrowed",
                    body: "Why not try a search to find your next read!",
                    searchBook: "Search for a book"
                },
                finished: {
                    title: "No books were borrowed",
                    body: "Books that you borrowed and returned will appear here."
                }
            },
            filter: {
                current: "In progress",
                finished: "Finished"
            }
        },
        changePassword: {
            title: "Modify your password"
        },
        chooseAccountProduct: {
            intro: "As your library grows you may need to upgrade your plan to gain more capacity and additional features. These are the current available plans.",
            title: "Choose your plan"
        },
        chooseGroupProduct: {
            intro: "Groups are a great way for people to share their books with each other in a single convenient place. Personal plans are ideal for use with your family, friends or a book club, while professional plans are designed for businesses, non-profits and organisations.",
            tab: {
                label: {
                    pro: 'Professional plans',
                    personal: 'Personal plans'
                }
            },
            title: "Choose your group plan"
        },
        community: {
            empty: {
                title: "Nothing here yet!",
                body: "You don't have any followers and you aren't following anyone.",
                bodyNoFollowers: "You don't have any follower.",
                bodyNoFollowing: "You aren't following anyone.",
                invite: "Invite some people"
            },
            filter: {
                all: "All",
                followers: "Followers",
                following: "Following"
            },
            inviteButton: "Invite"
        },
        contact: {
            title: "Contact us"
        },
        deviceContacts: {
            title: "Invite your contacts",
            info: "💡 Select the emails of the people you wish to invite then 👇 press the \"Invite\" button",
            submit: "Invite",
            contactsStatus: {
                isYou: "It's you!",
                alreadyInvitedByYou: "Already invited",
                alreadyInvitedByOther: "Already invited",
                existingUser: "Already user",
                existingUserFollowEachOther: "Already user",
                existingUserFollowedByYou: "Already user",
                existingUserFollowingYou: "Already user",
            }
        },
        discussion: {
            title: "Discussion with %{nickname}",
            read: "Read",
            unknown: "unknown"
        },
        discussions: {
            title: "Messages",
            discussion: {
                accountDeleted: "Account deleted",
                noMessage: "No messages"
            },
            empty: {
                title: "No messages",
                body: "Your discussions with other users will appear here."
            }
        },
        favourites: {
            empty: {
                title: "No favourited books",
                body: "Press the ❤️ icon when viewing a book to add it here."
            }
        },
        filterBooks: {
            title: "Filters"
        },
        followRequests: {
            title: "Follow requests",
            empty: {
                title: "No follow requests",
                body: "Because your account is private users must request to follow you."
            }
        },
        followers: {
            title: "Followers",
            empty: {
                title: "No followers yet",
                body: "%{nickname} doesn't have any followers. Be the first to follow them.",
                bodyPrivate: "%{nickname} doesn't have any followers.",
                bodyYourself: "Users that follow you will appear here.",
                follow: "Follow user"
            }
        },
        following: {
            title: "Following",
            empty: {
                title: "Not following anyone",
                body: "%{nickname} isn't yet following any other users.",
                bodyYourself: "Users that you follow will appear here."
            }
        },
        group: {
            collections: {
                add: "New",
                info: "Start creating collections to better organise your group's books."
            },
            empty: {
                memberOrAdmin: {
                    title: "This group doesn't yet have any books!",
                    body: "Start by adding your books to this group.",
                    addYourBooks: "Add your books"
                },
                user: {
                    title: "This group doesn't yet have any books!",
                    body: "Request to join and start adding yours.",
                    bodyRequestWaiting: "Start adding yours as soon as your request is accepted.",
                    cancelJoinRequest: "Cancel request to join group",
                    joinGroup: "Send request to join group"
                }
            },
            noResults: {
                title: "No books found",
                body: "Using the search term \"%{pattern}\".",
                showAll: "Show all"
            },
            failed: {
                title: "⚠️ Group error",
                body: "Could not retrieve group. Try again later.",
                goBack: "Return to previous page",
                goHome: "Go to home page"
            },
            header: {
                memberCountLabel: {
                    one: "Member",
                    other: "Members"
                }
            },
            notFound: {
                title: "Group not found",
                body: "It may have been deleted or the link is incorrect.",
                goBack: "Return to previous page",
                goHome: "Go to home page"
            },
            onSiteInfo: {
                availableOnSite: "Available on-site",
                availableForSelfService: "Available for self-service"
            }
        },
        groupCollection: {
            empty: {
                title: "This group collection is empty!",
                addBooks: "Add some books",
                bodyAdmin: "Start adding some books, it's quick and easy.",
                bodyUser: "No books have been added."
            },
            failed: {
                title: "⚠️ Group collection error",
                body: "Could not retrieve group collection. Try again later.",
                goBack: "Return to previous page",
                goHome: "Go to home page"
            },
            info: {
                about: "About",
                ago: " ago",
                books: {
                    one: "%{count} book",
                    other: "%{count} books",
                    zero: "%{count} books"
                },
                created: " created",
                createdBy: " created by ",
                groupCollectionWith: "Collection with "
            },
            notFound: {
                title: "Group collection not found",
                body: "It may have been deleted or the link is incorrect.",
                goBack: "Return to previous page",
                goHome: "Go to home page"
            }
        },
        groupMembers: {
            title: "Group members"
        },
        groupProduct: {
            title: "Plan details"
        },
        groupProductPro: {
            title: "Pro plan details"
        },
        groupProductCheckoutSuccess: {
            title: "Subscription confirmation",
            subscriptionStarted: "Your subscription is active",
            cancelAnytime: "You can cancel your plan at anytime.",
            productSelected: "Your current active plan is:",
            procceedToGroup: "Visit your group"
        },
        groupRequests: {
            empty: {
                title: "No group requests",
                body: "This is where requests to join groups will appear."
            }
        },
        inviteToJoinGroup: {
            title: "Invite to join group",
            multipleTitle: "Invite multiple",
            tab: {
                empty: {
                    invited: {
                        title: "No invitations",
                        body: "Sent invitations that haven't yet been accepted or refused will appear here."
                    },
                    suggestions: {
                        title: "No suggestions",
                        body: "Users that you might want to invite will appear here."
                    }
                },
                label: {
                    invited: "Invited",
                    suggestions: "Suggestions"
                }
            }
        },
        inviteUsers: {
            title: "Invite people to join MyBlio",
            notif: {
                noContactsFound: "We couldn't find any contacts"
            },
            labelAccessContacts: "Choose from my contacts",
            btnAccessContacts: "Access contacts",
            or: "or"
        },
        lend: {
            title: "Lend book",
            suggestedUser: {
                actionLabel: "Lend"
            },
            suggestedUsers: {
                title: "Suggestions",
                empty: {
                    title: "No suggestions",
                    body: "Users that you might want to lend to will appear here."
                }
            }   
        },
        lending: {
            empty: {
                current: {
                    title: "No books being lent",
                    body: "You can indicate books that you are currently lending from your library.",
                    goToLibrary: "Go to your library"
                },
                finished: {
                    title: "No books were lent", 
                    body: "Books that you lent and were returned will appear here."
                }
            },
            indicateReturn: "Indicate return",
            confirmReturn: "Confirm return",
            filter: {
                current: "In progress",
                finished: "Finished"
            }
        },
        list: {
            empty: {
                title: "This list is empty",
                addBooks: "Add books",
                bodyOwner: "Start adding books to this list.",
                bodyPossessed: "Books marked as possessed will appear here.",
                bodyRead: "Books marked as read will appear here.",
                bodyReading: "Books marked as reading will appear here.",
                bodyToRead: "Books marked as to read will appear here.",
                bodyUnread: "Books marked as unread will appear here.",
                bodyUser: "%{nickname} hasn't yet added any books here."
            },
            failed: {
                title: "⚠️ List error",
                body: "Could not retrieve list. Try again later.",
                goBack: "Return to previous page",
                goHome: "Go to home page"
            },
            info: {
                about: "About",
                ago: " ago",
                books: {
                    one: "%{count} book",
                    other: "%{count} books",
                    zero: "%{count} books"
                },
                created: " created",
                createdBy: " created by ",
                listWith: "List with "
            },
            notAllowed: {
                title: "✋ Not permitted",
                body: "Sorry! You're not allowed to view this list.",
                goBack: "Return to previous page",
                goHome: "Go to home page"
            },
            notFound: {
                title: "List not found",
                body: "It may have been deleted or the link is incorrect.",
                goBack: "Return to previous page",
                goHome: "Go to home page"
            },
            searchHeader: {
                matches: {
                    one: "found",
                    other: "found",
                    zero: "found"
                },
                showAll: "Show all"
            }
        },
        lists: {
            noResults: {
                title: "No books found in your lists",
                patternAndFilterBody: "Using the search term \"%{pattern}\" and filter(s).",
                filterBody: "Using the current filter(s).",
                patternBody: "Using the search term \"%{pattern}\".",
                showAll: "Show all"
            },
            seeAll: "See all"
        },
        myGroups: {
            empty: {
                title: "No groups to show",
                body: "You aren't yet a member of any groups. Start by joining or creating one.",
                createGroup: "Create a group"
            }
        },
        notFound: {
            empty: {
                title: "This screen doesn't exist",
                body: "The link is either incorrect or no longer exists.",
                goHome: "Return to home"
            }
        },
        premiumAccountOnly: {
            title: "Premium functionnality",
            keywords: {
                p1: "To be able to add custom keywords you will need to upgrade to one of the following plans:"
            },
            lendsHistory: {
                p1: "To be able to view your borrow/lends history you will need to upgrade to one of the following plans:"
            },
            iframe: {
                p1: "To be able to use the iframe fonctionality you will need to upgrade to one of the following plans:"
            },
            lendDuration: {
                p1: "To be able to set a lend duration you will need to upgrade to one of the following plans"
            }
        },
        premiumGroupOnly: {
            title: "Premium functionnality",
            collections: {
                p1: "To be able to add collections to your group you will need to upgrade to one of the following plans:"
            },
            multipleAdmins: {
                p1: "To be able to add multiple admins to your group you will need to upgrade to one of the following plans:"
            },
            iframe: {
                p1: "To be able to use the iframe you will need to upgrade to one of the following plans:"
            },
            selfService: {
                p1: "To be able to use self-service borrowing you will need to upgrade to one of the following plans:"
            }
        },
        prePaymentAccountProduct: {
            title: "Confirmation",
            recap: "Almost done!",
            productSelected: "You have chosen the following plan:",
            cancelAnytime: "You can cancel your plan at anytime.",
            procceedToPayment: "Proceed to payment"
        },
        prePayementGroupProduct: {
            title: "Confirmation",
            groupCreated: "Group successfully created",
            groupModified: "Group ready to upgrade",
            productSelected: "You have chosen the following plan:",
            cancelAnytime: "You can cancel your plan at anytime.",
            procceedToPayment: "Proceed to payment"
        },
        product: {
            tariff: {
                free: 'Free'
            },
            interval: {
                month: 'month',
                year: 'year',
                monthly: 'monthly',
                yearly: 'yearly'
            },
            choose: "Choose",
            findOutMore: "Details",
            quotas: {
                members: 'members',
                books: 'books',
                keywords: "keywords",
                collections: 'collections'
            },
            yourCurrentPlan: "Your current plan"
        },
        profile: {
            title: "",
            nav: {
                followRequests: "Follow requests",
                modifyMyAccount: "Account options",
                modifyMyProfile: "Modify my profile",
                modifyMySettings: "Modify my settings",
                upgradeAccountProduct: "Upgrade to premium"
            }
        },
        scan: {
            footer: {
                title: "Scan book's barcode",
                addManuallyBtn: "Add manually",
                else: "Or",
                searchBtn: "Search by keywords"
            },
            result: {
                addBookBtn: "Add this book"
            }
        },
        search: {
            title: "Search MyBlio",
            submitSearch: "Search",
            beforeSearch: {
                title: "Type a query above",
                body: "You can search for books, users and groups."
            },
            noBookResults: {
                title: "No books found",
                body: "Using the search term \"%{pattern}\"."
            },
            noGroupResults: {
                title: "No groups found",
                body: "Using the search term \"%{pattern}\"."
            },
            noUserResults: {
                title: "No users found",
                body: "Using the search term \"%{pattern}\"."
            },
            tab: {
                label: {
                    books: "Books",
                    groups: "Groups",
                    users: "Users"
                }
            }
        },
        settings: {
            title: "Settings",
            foundInSearch: {
                title: "Findable in search",
                description: "Your account will appear in search results."
            },
            messagesByEmail: {
                title: "Receive messages by email",
                description: "Messages sent using Myblio are also sent to your email address."
            },
            newsletter: {
                title: "Receive newsletter",
                description: "Sent out monthly with news about myBlio."
            },
            notifications: {
                title: "Notifications",
                description: "Be kept informed about various events."
            },
            notifsByEmail: {
                title: "Notifications by email",
                description: "Be kept informed about various events."
            },
            languages: {
                en: 'English',
                fr: 'French',
                default: 'Automatic'
            },
            lendDurations: {
                none: "No duration",
                oneWeek: "1 week",
                twoWeeks: "2 weeks",
                threeWeeks: "3 weeks",
                fourWeeks: "4 weeks",
            },
            private: {
                title: "Private profile",
                description: "Only accepted followers can view your profile."
            }
        },
        shareGroup: {
            title: "Share your group",
            labels: {
                "iframe-code": "Code :"
            },
            shareLinkInstructions: "Copy the following link to share your group.",
            shareLinkToLibraryTitle: "Share the link to your group",
            shareWithIframeInstructions: "Use the following <iframe> code to display your group's books on your website or blog. You can modify the 'width' and 'height' values depending on where it will appear.",
            shareWithIframeTitle: "Display your group on your website",
            selfServicePosterTitle: "Self-service borrowing poster",
            selfServicePosterInstructions: "This poster includes a QR code that allows members of in your group to borrow books available for self-service. Print it and display it next to where the books are located.",
            selfServicePosterBtn: "Download poster",
            selfServicePosterNoBooksAvailable: "You don't have any books available for self-service"
        },
        shareWeb: {
            title: "Share your library",
            labels: {
                "iframe-code": "Code :"
            },
            noPubliclyVisibleShelves: "None of your shelves are currently visible publicly. To change this you'll need to set the visibility to 'public' in each shelf's settings.",
            notAllShelvesArePubliclyVisible: "Currently %{publiclyVisibleShelvesCount} of your shelves are publicly visible. You can change their visibility in each shelf's settings.",
            shareLinkInstructions: "Copy the following link to share your library. Only shelves and lists with visibility set to 'public' will be shown.",
            shareLinkToLibraryTitle: "Share the link to your library",
            shareOnSocialMediaTitle: "Share your library on social media",
            shareWithIframeInstructions: "Use the following <iframe> code to display your library on your website. You can modify the 'width' and 'height' values depending on where it will appear.",
            shareWithIframeTitle: "Display your library on your website or blog",
            twitter: {
                text: "Check out my library on MyBlio!"
            }
        },
        shelf: {
            empty: {
                title: "This shelf is empty",
                addBooks: "Add books",
                bodyOwner: "Start adding books to this shelf.",
                bodyUser: "%{nickname} hasn't yet added any books here."
            },
            failed: {
                title: "⚠️ Shelf error",
                body: "Could not retrieve shelf. Try again later.",
                goBack: "Return to previous page",
                goHome: "Go to home page"
            },
            searchHeader: {
                matches: {
                    one: "matches phrase",
                    other: "match phrase",
                    zero: "match phrase"
                },
                showAll: "Show all"
            },
            info: {
                ago: " ago",
                books: {
                    one: "%{count} book",
                    other: "%{count} books",
                    zero: "%{count} books"
                },
                created: " created",
                createdBy: " created by ",
                shelfWith: "Shelf with "
            },
            notAllowed: {
                title: "✋ Not permitted",
                body: "Sorry! You're not allowed to view this shelf.",
                goBack: "Return to previous page",
                goHome: "Go to home page"
            },
            notFound: {
                title: "Shelf not found",
                body: "It may have been deleted or the link is incorrect.",
                goBack: "Return to previous page",
                goHome: "Go to home page"
            }
        },
        shelves: {
            empty: {
                title: "Your library is empty",
                body: "Start adding some books, it's quick and easy.",
                addBooks: "Add your books"
            },
            noResults: {
                title: "No books found in your shelves",
                patternAndFilterBody: "Using the search term \"%{pattern}\" and filter(s).",
                filterBody: "Using the current filter(s).",
                patternBody: "Using the search term \"%{pattern}\".",
                showAll: "Show all"
            },
            seeAll: "See all"
        },
        subscriptions: {
            title: "Subscriptions",
            cancelled: "Your subscription was cancelled and will end on the %{date}",
            currentPeriod: "Current period from %{from} to %{to}",
            modify: "Modify",
            month: "month",
            year: "year",
            empty: {
                title: "No subcriptions found",
                body: "You don't have any active subscriptions."
            }
        },
        takePhoto: {
            title: "Photograph book's cover",
            retake: "Retake",
            use: "Use"
        },
        terms: {
            title: "Terms and conditions"
        },
        updateBook: {
            title: "Update book",
            submit: "Update"
        },
        updateBookReview: {
            title: "Update review",
            submit: "Update"
        },
        updateGroup: {
            title: "Update group",
            submit: "Update",
            actionProduct: "modify"
        },
        updateGroupCollection: {
            title: "Update collection",
            submit: "Update"
        },
        updateList: {
            title: "Update list",
            submit: "Update"
        },
        updateProfile: {
            title: "Modify profile"
        },
        updateShelf: {
            title: "Update shelf",
            submit: "Update"
        },
        updateShelfShareBooksGroup: {
            title: "Shelf options",
            submit: "Update"
        },
        updateUserKeyword: {
            title: "Update keyword",
            submit: "Update",
            delete: "Delete keyword",
            booksUsingKeyword: {
                one: "This keyword is associated with one book.",
                other: "This keyword is associated with %{count} books.",
                zero: "This keyword isn't associated with any books."
            }
        },
        user: {
            cancelRequest: "Cancel request",
            requestSent: "Request sent",
            failed: {
                title: "⚠️ User error",
                body: "Could not retrieve user. Try again later.",
                goBack: "Return to previous page",
                goHome: "Go to home page"
            },
            noPublicLists: {
                title: "No lists no show you!",
                body: "%{nickname} doesn't have any publicly viewable lists."
            },
            noPublicShelves: {
                title: "No shelves to show you!",
                body: "%{nickname} doesn't have any publicly viewable shelves."
            },
            notFound: {
                title: "User not found",
                body: "It may have been deleted or the link is incorrect.",
                goBack: "Return to previous page",
                goHome: "Go to home page"
            },
            private: "This account is private",
            requestFollow: "Send follow request",
            tab: {
                label: {
                    lists: "Lists",
                    shelves: "Shelves"
                }
            }
        },
        welcome: {
            gotoLoginScreen: "Login",
            gotoSignupScreen: "Signup"
        }
    },
    sharing: {
        book: {
            email: {
                owner: {
                    body: "See it here: %{url}",
                    subject: "Check out the book '%{name}' on MyBlio"
                },
                user: {
                    body: "See it here: %{url}",
                    subject: "Check out the book '%{name}' on MyBlio"
                }
            },
            native: {
                owner: "Check out the book '%{name}' on MyBlio %{url}",
                user: "Check out the book '%{name}' on MyBlio %{url}"
            },
            twitter: {
                owner: "Check out the book '%{name}'",
                user: "Check out the book '%{name}'"
            }
        },
        group: {
            email: {
                owner: {
                    body: "See the group here: %{url}",
                    subject: "Check out my group '%{name}' on MyBlio"
                },
                user: {
                    body: "See the group here: %{url}",
                    subject: "Check out the group '%{name}' on MyBlio"
                }
            },
            native: {
                owner: "Check out my group '%{name}' on MyBlio %{url}",
                user: "Check out the group '%{name}' on MyBlio %{url}"
            },
            twitter: {
                owner: "Check out my group '%{name}'",
                user: "Check out the group '%{name}'"
            }
        },
        groupCollection: {
            email: {
                user: {
                    body: "See the group's collection here: %{url}",
                    subject: "Check out this group collection '%{name}' on MyBlio"
                }
            },
            native: {
                user: "Check out the group collection '%{name}' on MyBlio %{url}"
            },
            twitter: {
                user: "Check out the group collection '%{name}'"
            }
        },
        inviteUrl: {
            native: {
                user: "I'm using MyBlio to manage my book library. Join for free and let's start sharing our books! %{url}"
            }
        },
        list: {
            email: {
                owner: {
                    body: "See it here: %{url}",
                    subject: "Check out my book list '%{name}' on MyBlio"
                },
                user: {
                    body: "See it here: %{url}",
                    subject: "Check out this book list '%{name}' on MyBlio"
                }
            },
            native: {
                owner: "Check out '%{name}' my book list on MyBlio %{url}",
                user: "Check out '%{name}' a book list on MyBlio %{url}"
            },
            twitter: {
                owner: "Check out '%{name}' my book list",
                user: "Check out '%{name}' a book list"
            }
        },
        shelf: {
            email: {
                owner: {
                    body: "See it here: %{url}",
                    subject: "Check out my book shelf '%{name}' on MyBlio"
                },
                user: {
                    body: "See it here: %{url}",
                    subject: "Check out this book shelf '%{name}' on MyBlio"
                }
            },
            native: {
                owner: "Check out '%{name}' my book shelf on MyBlio %{url}",
                user: "Check out '%{name}' a book shelf on MyBlio %{url}"
            },
            twitter: {
                owner: "Check out '%{name}' my book shelf",
                user: "Check out '%{name}' a book shelf"
            }
        },
        user: {
            email: {
                owner: {
                    body: "See them here: %{url}",
                    subject: "Check out my books 📚 on MyBlio"
                },
                user: {
                    body: "See them here: %{url}",
                    subject: "Check out this user's (%{name}) books 📚 on MyBlio"
                }
            },
            native: {
                owner: "Check out my books 📚 on MyBlio %{url}",
                user: "Check out this user's (%{name}) books 📚 on MyBlio %{url}"
            },
            twitter: {
                owner: "Check out my books 📚",
                user: "Check out this user's (%{name}) books 📚"
            }
        }
    },
    shelf: {
        add: "Add a shelf",
        visibility: {
            followers: "Followers",
            private: "Private",
            public: "Public"
        }
    },
    sidebar: {
        addButton: "Add",
        credit: "Made in Bordeaux with ♥️",
        logout: "Logout",
        navItems: {
            contact: "Contact us",
            findOutMore: "More information",
            followRequests: "Follow requests",
            messages: "Messages",
            profile: "Profile",
            settings: "Settings",
            share: "Share"
        },
        public: {
            about: "Use MyBlio to manage your books, find other readers and create groups.",
            login: "Login",
            or: "or",
            signup: "Create an account"
        },
        welcome: "Welcome,"
    },
    tabs: {
        groups: {
            header: {
                title: "Groups"
            },
            tabBarLabel: "Groups",
            tabs: {
                groupRequests: {
                    title: "Requests"
                },
                myGroups: {
                    title: "Mine"
                },
                publicGroups: {
                    title: "Public"
                }
            }
        },
        home: {
            header: {
                title: "Home"
            },
            tabBarLabel: "Home",
            tabs: {
                activity: {
                    title: "Activity"
                },
                community: {
                    title: "Community"
                }
            }
        },
        lends: {
            header: {
                title: "Exchanges"
            },
            tabBarLabel: "Exchanges",
            tabs: {
                bookRequests: {
                    title: "Requests"
                },
                borrowing: {
                    title: "Borrowing"
                },
                lending: {
                    title: "Lending"
                }
            }
        },
        library: {
            header: {
                title: "My library"
            },
            tabBarLabel: "Library",
            tabs: {
                books: {
                    title: "Books",
                    tabs: {
                        all: {
                            title: "All"
                        },
                        lists: {
                            title: "Lists"
                        },
                        shelves: {
                            title: "Shelves"
                        }
                    }
                },
                favourites: {
                    title: "Favourites"
                }
            }
        }
    },
    user: {
        description: {
            none: "No description yet!"
        },
        followRequest: {
            accept: "Accept",
            refuse: "Refuse"
        },
        followsYou: "Follows you",
        location: {
            unknown: "Unknown"
        },
        notFound: "[User deleted]",
        echelon: {
            premium: "Premium",
            basic: "Free"
        }
    }
}