import { Platform, StyleSheet, Dimensions, Share } from 'react-native';
import { startActivityAsync, ActivityAction } from 'expo-intent-launcher';
import Constants from 'expo-constants';
import * as Sharing from 'expo-sharing';
import * as MailComposer from 'expo-mail-composer';
import { Linking } from 'react-native';
import Dayjs from 'dayjs';
const customParseFormat = require('dayjs/plugin/customParseFormat')
Dayjs.extend(customParseFormat)
import ISBN from 'isbn3';
import Color from 'color';
import { getFormErrorNotif } from './notifs';
import { Notifier } from 'react-native-notifier';
import * as ImageManipulator from 'expo-image-manipulator';
import * as Notifications from 'expo-notifications';
import { CommonActions } from '@react-navigation/native';
import { navigation } from '../navigation/RootNavigation';
import { emptyStores } from '../actions';
import { createFilter } from 'react-native-search-filter';
import { getAverageColor } from '../utils/blurhash';
import * as Application from 'expo-application';
import * as Updates from 'expo-updates';
import { dependencies } from '../package.json';
import { interpolate, Extrapolate } from 'react-native-reanimated';
import { 
    DOMAIN, 
    DEFAULT_LISTS, 
    LIST_RULES, 
    USER_HEADER_HEIGHT, 
    USER_HEADER_HEIGHT_MIN,
    GROUP_HEADER_HEIGHT_MIN,
    MAIN_COLOR,
    SECONDARY_COLOR,
    GRAY_LINE_COLOR,
    ISO_DATE_FORMAT_1970 ,
    LARGE_SCREEN_THRESHOLD,
    DRAWER_WIDTH,
    CANONICAL_PATHS,
    CANONICAL_LOGGED_PATHS,
    MODAL_MAX_WIDTH,
    SERVER_FORM_PARAMS_TO_YUP_FIELDS,
    READING_STATUSES
} from '../constants';

export function isWeb() {
    return Platform.OS === 'web'
}

export function isIOS() {
    return Platform.OS === 'ios'
}

export function isAndroid() {
    return Platform.OS === 'android'
}

export function isNative() {
    return isIOS() || isAndroid()
}

export function isLargeScreen() {
    const { width } = Dimensions.get('window');
    return width >= LARGE_SCREEN_THRESHOLD;
}

export function getModalType(windowWidth) {
    if(isWeb() || windowWidth >= 500) return 'transparentModal';
    return 'modal';
}

export function handleApi401(dispatch) {
    dispatch(emptyStores()).then(() => {
        navigation.dispatch(
            CommonActions.reset({
                index: 0,
                routes: [{ 
                    name: 'Auth', 
                    state: { routes: [{ name: 'SignIn' }]} 
                }],
            })
        );
    })
}

export function isEmpty(myObject) {
    for(var key in myObject) {
        if (myObject.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
}

export function shortenText(text, limit) {
    let shortenedText = text
    if(text && limit && text.trim().length > limit) {
        shortenedText = text.substring(0, limit).trim().concat('', '…')
    }
    return shortenedText
}

export function openAppSettings() {
    if(isAndroid()) {
        const { package:packageName } = __DEV__ ? { package: 'host.exp.exponent' } : Constants.manifest.android
        const data = `package:${packageName}`
        startActivityAsync(
            ActivityAction.APPLICATION_DETAILS_SETTINGS,
            { data }
        );
    } else {
        Linking.openURL('app-settings:')  
    }
}

export function coverUri(cover, coverHash) {
    if(!cover) {
        return '';
    } else if(cover.indexOf('http') > -1) {
        return cover;
    }
    const hashParam = coverHash ? `?${coverHash}` : '';
    return `${DOMAIN}${cover}${hashParam}`;
}

export function isValidBookIsbn(code) {
    const parsedIbsn = ISBN.parse(code);
    return parsedIbsn !== null
}

export function sortByDate(arrayToSort, key, reverse=false) {
    let keys = key.split('.')
    return arrayToSort.sort( (a, b) => {
        let dateA = a;
        let dateB = b;
        keys.forEach((key) => {
            dateA = dateA[key] || ''
            dateB = dateB[key] || ''
        })
        let aDate = new Date(dateA) / 1000;
        let bDate = new Date(dateB) / 1000;
        let creationDateDiff = aDate - bDate
        if(creationDateDiff > 0) {
            return reverse ? 1 : -1;
        } else if (creationDateDiff < 0) {
            return reverse ? -1 : 1;
        } else {
            return 0
        }
    })
}

function getFirstAuthorSurname(authors) {
    const parts = authors.split(',');
    const firstAuthor = parts[0].trim();
    const firstAuthorParts = firstAuthor.split(' ');
    const firstAuthorSurname = firstAuthorParts[firstAuthorParts.length-1].trim();
    return firstAuthorSurname || firstAuthorParts[0] || authors;
}

export function sortKeywordsAlphabetically(arrayToSort, locale) {
    const keyToSortBy = locale.indexOf('fr') > -1 ? 'nameFr' : 'nameEn';
    return arrayToSort.sort((a, b) => { 
        const nameA = prepareSortString(a, a[keyToSortBy] ? keyToSortBy : 'name');
        const nameB = prepareSortString(b, b[keyToSortBy] ? keyToSortBy : 'name');
        return _sortAlphabetically(nameA, nameB);
    })
}

export function sortAlphabetically(arrayToSort, key, reverse=false) {
    return arrayToSort.sort((a, b) => {
        const [nameA, nameB] = prepareSortStrings(a, b, key);
        return _sortAlphabetically(nameA, nameB, reverse);
    });
}

export function sortContactsAlphabetically(arrayToSort, reverse=false) {
    return arrayToSort.sort((a, b) => {
            const nameA = prepareSortString(a, a.lastName ? 'lastName' : 'firstName');
            const nameB = prepareSortString(b, b.lastName ? 'lastName' : 'firstName');
            return _sortAlphabetically(nameA, nameB, reverse);
    });
}

function sortBooksByTitle(arrayToSort, reverse=false) {
    return arrayToSort.sort((a, b) => {
        const [nameA, nameB] = prepareSortStrings(a, b, 'title');
        const pos = _sortAlphabetically(nameA, nameB, reverse);
        if(pos == 0) return _sortByPublishedDate(a, b);
        return pos
    });
}

function sortBooksByAuthor(arrayToSort, reverse=false) {
    return arrayToSort.sort((a, b) => {
        let [nameA, nameB] = prepareSortStrings(a, b, 'author');
        nameA = getFirstAuthorSurname(nameA);
        nameB = getFirstAuthorSurname(nameB);
        const pos = _sortAlphabetically(nameA, nameB, reverse);
        if(pos == 0) return _sortByPublishedDate(a, b);
        return pos
    });
}

function sortBooksByCollection(arrayToSort, reverse=false) {
    return arrayToSort.sort((a, b) => {
        const [collectionNameA, collectionNameB] = prepareSortStrings(a, b, 'collection');
        const [titleNameA, titleNameB] = prepareSortStrings(a, b, 'title');
        if(collectionNameA && !collectionNameB) {
            return -1;
        } else if(!collectionNameA && collectionNameB) {
            return 1;
        } if(collectionNameA && collectionNameB) {
            const posCollectionName = _sortAlphabetically(collectionNameA, collectionNameB, reverse);
            if(posCollectionName === 0) {
                return _sortByCollectionNbr(a, b);
            } else {
                return posCollectionName;
            }

        }
        return _sortAlphabetically(titleNameA, titleNameB, reverse)
    });
}

function prepareSortStrings(a, b, key) {
    let nameA = prepareSortString(a, key);
    let nameB = prepareSortString(b, key);
    return [nameA, nameB];
}

function prepareSortString(obj, key) {
    return obj[key] ? normalizeString(obj[key]) : '';
}

function normalizeString(str) {
    return str.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

function _sortByCollectionNbr(a, b) {
    const collectionNbrA = a.collectionNbr !== null ? a.collectionNbr : 0;
    const collectionNbrB = b.collectionNbr !== null ? b.collectionNbr : 0;
    return collectionNbrA - collectionNbrB;
}

function _sortByPublishedDate(a, b, reverse=false) {
    const publishedDateA = _getDateFromPublished(a);
    const publishedDateB = _getDateFromPublished(b);
    if(!publishedDateA && !publishedDateB) return 0;
    if(!publishedDateA && publishedDateB) return 1;
    if(publishedDateA && !publishedDateB) return -1;
    const publishDiff = publishedDateA.diff(publishedDateB, 'day');
    if(publishDiff > 0) {
        return reverse ? 1 : -1;
    } else if (publishDiff < 0) {
        return reverse ? -1 : 1;
    } else {
        return 0
    }
}

function _getDateFromPublished(book) {
    const year = book.year;
    const month = (() => {
        if(!year) return null;
        return book.month || '1';
    })()
    const day = (() => {
        if(!year || (!year && !month)) return null;
        return book.day || '1';
    })()
    return year ? Dayjs(`${day}/${month}/${year}`, 'D/M/YYYY') : null;
}

function _sortAlphabetically(a, b, reverse) {
    if(a < b){
        return reverse ? 1 : -1;
    } else if(a > b){
        return reverse ? -1 : 1;
    }
    return 0;
}

export function sortShelvesBooks(books, sortType) {
    if(!books.length || !sortType) return books
    switch (sortType) {
        case 'added_asc':
            return sortByDate(books, 'created', true)
        case 'added_desc':
            return sortByDate(books, 'created')
        case 'title_asc':
            return sortBooksByTitle(books)
        case 'title_desc':
            return sortBooksByTitle(books, true)
        case 'author_asc':
            return sortBooksByAuthor(books)
        case 'author_desc':
            return sortBooksByAuthor(books, true)
        case 'collection_asc':
            return sortBooksByCollection(books)
        case 'collection_desc':
            return sortBooksByCollection(books, true)
        default:
            return books 
    }  
}

export function sortGroupBooks(books, sortType, groupId) {
    return _sortBooksByInGroup(books, sortType, `groups.${groupId}`)
}

export function sortGroupCollectionBooks(books, sortType, groupCollectionId) {
    return _sortBooksByInGroup(books, sortType, `groupCollections.${groupCollectionId}`)
}

export function sortGroupCollections(groupCollections) {
    if(groupCollections.length && groupCollections[0].customOrder === null) {
        return sortByDate(groupCollections, 'created', true);
    }
    return groupCollections.sort((a, b) => {
        if(a.customOrder > b.customOrder) {
            return 1;
        } else if(a.customOrder < b.customOrder) {
            return -1;
        } else {
            return 0
        }
    })
}

function _sortBooksByInGroup(books, sortType, keyToAddedDate) {
    if(!books.length || !sortType) return books
    switch (sortType) {
        case 'added_asc':
            return sortByDate(books, keyToAddedDate, true)
        case 'added_desc':
            return sortByDate(books, keyToAddedDate)
        case 'title_asc':
            return sortBooksByTitle(books)
        case 'title_desc':
            return sortBooksByTitle(books, true)
        case 'author_asc':
            return sortBooksByAuthor(books)
        case 'author_desc':
            return sortBooksByAuthor(books, true)
        case 'collection_asc':
            return sortBooksByCollection(books)
        case 'collection_desc':
            return sortBooksByCollection(books, true)
        default:
            return books 
    }
}

export function sortListsAlphabetically(lists) {
    const userLists = lists.filter(list => !DEFAULT_LISTS.includes(list.type));
    const defaultLists = DEFAULT_LISTS.map(type => lists.find(list => list.type === type)).filter(list => list);
    return defaultLists.concat(sortAlphabetically(userLists, 'name'));
}

export function getListName(list, t) {
    if(DEFAULT_LISTS.includes(list.type)) {
        return t(list.name);
    }
    return list.name
}

export function getListDescription(list, t) {
    if(DEFAULT_LISTS.includes(list.type)) {
        return t(list.description);
    }
    return list.description
}

export function avalailableListsForBook(lists, possessed) {
    return lists.filter(list => {
        const type = list.type ?? 'user';
        const { canContainPossessedBooks, canAddBooks } = LIST_RULES[type];
        if((!canContainPossessedBooks && possessed) || !canAddBooks) return false;
        return true
    })
}

export function processLists(lists) {
    return {
        lists: [...lists],
        sortAlphabetically() {
            this.lists = sortListsAlphabetically(this.lists)
            return this;
        },
        filterAllowed(possessed) {
            this.lists = avalailableListsForBook(this.lists, possessed)
            return this;
        }
    }
}

export function hasBookAlreadyBeenAdded(books, providerRessourceIds) {
    let has = false;
    for(let key in books) {
        const book = books[key];
        providerRessourceIds.forEach(providerRessourceId => {
            if(book.providerRessourceIds && book.providerRessourceIds.includes(providerRessourceId)) {
                has = true;
            }
        })
        if(has) break;
    }
    return has;
}

export function goBackFromBook(navigation, activeBottomTab, group) {
    _goBackFrom(navigation, activeBottomTab, group ? () => navigateToGroup(navigation, group, 'replace') : undefined)
}

export function goBackFromShelf(navigation, activeBottomTab) {
    _goBackFrom(navigation, activeBottomTab)
}

export function goBackFromList(navigation, activeBottomTab) {
    _goBackFrom(navigation, activeBottomTab)
}

export function goBackFromGroup(navigation, activeBottomTab) {
    _goBackFrom(navigation, activeBottomTab)
}

export function goBackFromUser(navigation, activeBottomTab) {
    _goBackFrom(navigation, activeBottomTab)
}

export function goBackFromGroupCollection(navigation, activeBottomTab, group) {
    _goBackFrom(navigation, activeBottomTab, () => navigateToGroup(navigation, group, 'replace'))
}

function _goBackFrom(navigation, activeBottomTab, customNavigation) {
    const isInPublicStack = navigation.getParent().getState()?.routes[0]?.name == 'PublicStack';
    if(navigation.canGoBack() && navigation.getState()?.routes.length > 1) {
        navigation.goBack();
    } else if(customNavigation) {
        customNavigation()
    } else if(activeBottomTab && !isInPublicStack) {
        navigateToBottomTab(navigation, activeBottomTab);
    } else if(isInPublicStack) {
        navigation.navigate('Auth', { screen: 'Welcome' });
    } else {
        navigateToHomeTab(navigation, 'Activity');
    }
}

export function navigateToBottomTab(navigation, tab) {
    if(tab == 'HomeTab') navigateToHomeTab(navigation, 'Activity');
    if(tab == 'LibraryTab') navigateToLibraryTab(navigation, 'Shelves');
    if(tab == 'LendsTab') navigateToLendsTab(navigation, 'Borrowing');
    if(tab == 'GroupsTab') navigateToGroupsTab(navigation, 'PublicGroups');
}

export function isLent(lend) {
    return lend && lend.returnedDatetime == null;
}

export function getOtherIdFromLend(lend, context) {
    if(context == 'borrowing' || context == 'borrowed') return lend?.fromId;
    if(context == 'lending' || context == 'lent') return lend?.toId;
    return;
}

export function getOtherIdFromBorrowRequest(borrowRequest, context) {
    if(context == 'borrowRequestByMe') return borrowRequest?.toId;
    if(context == 'borrowRequestToMe') return borrowRequest?.fromId;
    return;
}

export function shouldAnimateUserHeader(scrollHeight, scrollContentHeight, headerHeight) {
    const contentHeight = scrollContentHeight ? scrollContentHeight - (headerHeight - USER_HEADER_HEIGHT_MIN) : scrollContentHeight;
    if(scrollHeight && 
       contentHeight && 
       contentHeight > scrollHeight) return true;
    return false;
}

export function shouldAnimateGroupHeader(scrollHeight, scrollContentHeight, headerHeight) {
    const contentHeight = scrollContentHeight ? scrollContentHeight - (headerHeight - GROUP_HEADER_HEIGHT_MIN) : scrollContentHeight;
    if(scrollHeight && 
       contentHeight && 
       contentHeight > scrollHeight) return true;
    return false;
}

export function shouldAnimateBookHeader(scrollHeight, scrollContentHeight, scrollDistance) {
    const contentHeight = scrollContentHeight ? scrollContentHeight - scrollDistance : scrollContentHeight;
    if(scrollHeight && 
        contentHeight && 
        contentHeight > scrollHeight) return true;
    return false;
}

export function isEven(value) {
    return (value%2 == 0);
}

export function doesActionTypeMatch(actionTypes, type) {
    return (() => {
        for(let actionType of actionTypes) {
            if(actionType.toString() == type) return true;
        }
        return false;
    })()
}

export function hasRequestedFollowUser(followRequests, userId, loggedUserId) {
    let hasRequested = false;
    followRequests.forEach((followRequest) => {
        if(followRequest.toId == userId && followRequest.fromId == loggedUserId) hasRequested = true;
    })
    return hasRequested;
}

export function getFollowRequestByMeToUser(followRequests, userId, loggedUserId) {
    let found;
    followRequests.forEach((followRequest) => {
        if(followRequest.toId == userId && followRequest.fromId == loggedUserId) found = followRequest;
    })
    return found;
}

export function getFollowRequestToMeFromUser(followRequests, userId, loggedUserId) {
    let found;
    followRequests.forEach((followRequest) => {
        if(followRequest.toId == loggedUserId && followRequest.fromId == userId) found = followRequest;
    })
    return found;
}

export function isDateYesterday(date) {
    const yesterday = Dayjs().subtract(1, 'day').startOf('day');
    return Dayjs(date).isSame(yesterday, 'day');
}

export function isDateThisYear(date) {
    const now = Dayjs().startOf('day');
    return Dayjs(date).isSame(now, 'year');
}

export function isDateThisWeek(date) {
    const now = Dayjs().startOf('day');
    return Dayjs(date).isSame(now, 'week');
}

export function isDateThisMonth(date) {
    const now = Dayjs().startOf('day');
    return Dayjs(date).isSame(now, 'month');
}

export function isDateToday(date) {
    const today = Dayjs().startOf('day');
    return Dayjs(date).isSame(today, 'day');
}

export function prepareUserSelectedImgData(useImage) {
    if(isNative()) {
        return { uri: useImage, name: 'image.jpg', type: 'image/jpeg' };
    } else if(isWeb()) {
        return useImage;
    }
}

export function prepareSelectedFileForForm(file) {
    if(isNative()) {
        return { uri: file.uri, name: file.name, type: file.mimeType };
    } else if(isWeb()) {
        return file.uri;
    }
}

export function fbShare(url, winWidth, winHeight) {
    var winTop = (screen.height / 2) - (winHeight / 2);
    var winLeft = (screen.width / 2) - (winWidth / 2);
    window.open('http://www.facebook.com/sharer.php?u=' + url, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight);
}

export function twitterShare(text, url, winWidth, winHeight, via) {
    if(!hashtags) { var hashtags = '' };
    var winTop = (screen.height / 2) - (winHeight / 2);
    var winLeft = (screen.width / 2) - (winWidth / 2);
    window.open('https://twitter.com/intent/tweet?text=' + text + '&url=' + url + '&via=' + via, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight);
}

export async function cropImageToSquare({ width, height, uri }, desiredSize) {
    let actions = [];
    let manipResult = [];
    if((width - height) > 1 || (width - height) < -1) { 
        const cropSize = Math.min(width, height);
        const originX = cropSize < width ? Math.floor((width - cropSize) / 2) : 0;
        const originY = cropSize < height ? Math.floor((height - cropSize) / 2) : 0;
        actions.push({ crop: { originX, originY, width: cropSize, height: cropSize }});
    }
    if(width > desiredSize && height > desiredSize) {
        actions.push({ resize: { width: desiredSize, height: desiredSize }});
    }
    for(let index in actions) {
        let action = actions[index];
        let uriToProcess = index > 0 ? manipResult[index-1].uri : uri
        if(actions.length) {
            manipResult[index] = await ImageManipulator.manipulateAsync(
                uriToProcess,
                [action],
                { compress: 1, format: ImageManipulator.SaveFormat.JPEG }
            );
        }
    }
    return manipResult.length && manipResult[manipResult.length-1]?.uri ? manipResult[manipResult.length-1].uri : undefined
}

export function getTabBarOptions() {
    return {
        animationEnabled: isNative(),
        swipeEnabled: false,
        tabBarItemStyle: isLargeScreen() ? { width: 'auto', minWidth: 130 } : {},
        tabBarLabelStyle: { fontSize: isLargeScreen() ? 16 : 15, textTransform: 'capitalize', color: SECONDARY_COLOR, fontFamily: 'Inter_400Regular' },
        tabBarIndicatorStyle: { height: 4, backgroundColor: MAIN_COLOR, marginBottom: -1 },
        tabBarStyle: { backgroundColor: 'white', elevation: 0, borderBottomWidth: StyleSheet.hairlineWidth, borderBottomColor: GRAY_LINE_COLOR, shadowColor: 'transparent' }
    }
}

export function navigateToHomeTab(navigation, tab) {
    navigation.navigate(
        'HomeTab', 
        { 
            screen: 'Home', 
            params: { 
                screen: 'HomeTabs', 
                params: { 
                    screen: tab 
                }
            }
        }
    )
}

export function navigateToLibraryTab(navigation, tab) {
    navigation.navigate(
        'LibraryTab', 
        { 
            screen: 'Library', 
            params: { 
                screen: 'LibraryTabs', 
                params: { 
                    screen: 'BooksTabs',
                    params: { 
                        screen: tab 
                    }
                }
            }
        }
    )
}

export function navigateToLendsTab(navigation, tab) {
    navigation.navigate(
        'LendsTab', 
        { 
            screen: 'Lends', 
            params: { 
                screen: 'LendsTabs', 
                params: { 
                    screen: tab 
                }
            }
        }
    )
}

export function navigateToGroupsTab(navigation, tab) {
    navigation.navigate(
        'GroupsTab', 
        { 
            screen: 'Groups', 
            params: { 
                screen: 'GroupsTabs', 
                params: { 
                    screen: tab 
                }
            }
        }
    )
}

export function navigateToBook(navigation, params, action='push') {
    const route = (() => {
        if(params.groupId) return 'GroupBook';
        return 'Book';
    })()
    navigation[action](route, params);
}

export function navigateToUser(navigation, params, action='push') {
    navigation[action]('User', params);
}

export function navigateToGroup(navigation, group, action='push') {
    navigation[action]('Group', { groupId: group.id, groupSlug: group.slug });
}

export function navigateToGroupCollection(navigation, groupCollection, group, action='push') {
    navigation[action]('GroupCollection', { groupCollectionId: groupCollection.id, groupId: group.id, groupSlug: group.slug });
}

export function displayGroupWebsiteUrl(url) {
    url = url.replace(/www./, ''); 
    url = url.replace(/http:\/\/|https:\/\//, '');
    url = url.split('/').shift();
    return url;
}

export function removeOldKeysFromState(state, freshData) {
    const keysToRemove = [];
    for(const [key, item] of Object.entries(state)) {
        const index =  freshData.findIndex(({id}) => id == item.id);
        if(index == -1) keysToRemove.push(key)
    }
    for(const keyToRemove of keysToRemove) {
        delete state[keyToRemove]
    }
}

export function uniq(a) {
    return [...new Set(a)];
}

export function isInModal(navigation) {
    if(navigation.getParent()?.getState()?.routeNames.includes('Modal')) return true;
    return false
}

export function openModalOrPushScreen(navigation, screen, params={}) {
    if(isInModal(navigation)) {
        navigation.push(screen, params);
    } else {
        navigation.navigate('Modal', { screen, params })
    }
}

export function getNewestActivity(activity) {
    if(!activity.length) return ISO_DATE_FORMAT_1970;
    const events = activity.map(({date}) => date);
    events.sort((a, b) => {
        let aDate = new Date(a) / 1000;
        let bDate = new Date(b) / 1000;
        let creationDateDiff = aDate - bDate;
        if(creationDateDiff > 0) {
            return -1;
        } else if (creationDateDiff < 0) {
            return 1;
        } else {
            return 0
        }
    })
    return events[0];
}

export function getTimeSinceNow(date, locale, timeAgoInWords) {
    const prefix = locale.indexOf('fr') === 0 ? 'il y a ' : '';
    const suffix = locale.indexOf('en') === 0 ? ' ago' : '';
    return prefix + timeAgoInWords(date, new Date()) + suffix;
}

export function keyExtractor(item) {
    return item.id;
} 

export function getInitialScreenWidth(navigation) {
    const { width:windowWidth } = Dimensions.get('window');
    const inModal = isInModal(navigation);
    if(inModal && windowWidth >= MODAL_MAX_WIDTH) {
        return MODAL_MAX_WIDTH;
    } else if (inModal && windowWidth < MODAL_MAX_WIDTH) {
        return windowWidth;
    }
    return isLargeScreen() ? windowWidth - DRAWER_WIDTH : windowWidth;
}

export function getLendContext(lend, loggedUserId) {
    if(lend.returnedDatetime || lend.returnedDatetime === null) {
        if(lend.toId == loggedUserId && lend.returnedDatetime === null) return 'borrowing';
        if(lend.toId == loggedUserId && lend.returnedDatetime) return 'borrowed';
        if(lend.fromId == loggedUserId && lend.returnedDatetime === null) return 'lending';
        if(lend.fromId == loggedUserId && lend.returnedDatetime) return 'lent';
    } else {
        if(lend.toId == loggedUserId) return 'borrowRequestToMe';
        if(lend.fromId == loggedUserId) return 'borrowRequestByMe';
    }
    return ''
}

export function getCanonicalPath(route, params, path, isUserLogged=false) {
    let canonicalPath = path;
    const paths = isUserLogged ? CANONICAL_LOGGED_PATHS : CANONICAL_PATHS;
    if(paths[route]) {
        for(let [index, value] of Object.entries(paths[route])) {
            const pathToMatch = insertUrlParams(index, params);
            if(pathToMatch === path) {
                canonicalPath = insertUrlParams(value, params);
                break;
            }
        }
    }
    return canonicalPath;
}

function insertUrlParams(path, pathParams) {
    let params = {...pathParams};
    if(path.indexOf(':') > -1) {
        const urlKeysToReplace = path.match(/:[a-zA-Z]*/g);
        for(let urlKeyToReplace of urlKeysToReplace) {
            const paramKey = urlKeyToReplace.substr(1);
            const param = params[paramKey];
            if(param) {
                path = path.replace(urlKeyToReplace, param);
                delete params[paramKey];
            }
        }
    }
    const urlParams = (() => {
        if(!params) return '';
        const arr = [];
        for(let [key, value] of Object.entries(params)) {
            if(key && value) {
                arr.push(`${key}=${value}`);
            }
        }
        return arr.length ? `?${arr.join('&')}` : '';
    })()
    return `${path}${urlParams}`;
}

export function dictToURI(dict) {
    let arr = []
    for(const key in dict){
        const value = dict[key];
        if(value) arr.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
    }
    return arr.join("&");
}

export function getBookCoverPath(cover, coverHash) {
    if(!cover) return '';
    if(cover && !coverHash) return cover;
    return `${cover}?${coverHash}`;
}

export function handleFormErrors(errors, setError, schema, keyToRemove) {
    errors.forEach(({ msg:message, param }) => {
        const parsedParam = (() => {
            let value;
            if(keyToRemove) value = param.replace(`${keyToRemove}.`, '');
            if(SERVER_FORM_PARAMS_TO_YUP_FIELDS[value]) {
                return SERVER_FORM_PARAMS_TO_YUP_FIELDS[value];
            }
            return value;
            
        })();
        if(schema.fields[parsedParam]) {
            setError(parsedParam, { type: 'manual', message })
        } else {
            Notifier.showNotification(getFormErrorNotif(message))
        }
    })
}

export function handleGeneralError(errors) {
    errors.forEach(({ msg }) => {
        if(msg.match(/\w\.\w/) === null) {
            Notifier.showNotification(getFormErrorNotif(msg));
        }
    })
}

export function getTagLabel(tag, locale) {
    return getTagOrKeywordLabelByLocale(tag, locale);
}

export function getKeywordLabel(keyword, locale) {
    return getTagOrKeywordLabelByLocale(keyword, locale);
}

function getTagOrKeywordLabelByLocale(item, locale) {
    if(item?.name) return item.name;
    if(locale.indexOf('fr') > -1) return item.nameFr;
    return item.nameEn;
}

export function isSmallScreen() {
    const { height } = Dimensions.get('window');
    return (height <= 650 ? true : false)
}

export function getResponsiveValue(values, axis) {
    const { width, height } = Dimensions.get('window');
    let value;
    if(axis == 'x') {
        let widthIndex = 0;
        const xsWidth   = [320, 360];
        const sWidth    = [360, 414];
        const mWidth    = [414, 480];
        const lWidth    = [480, 640];
        const xlWidth   = [640, 780];
        const xxlWidth  = [780, 1080];
        const xxxlWidth = [1080, 3000];
        if(width >= xsWidth[0]   && width < xsWidth[1])   widthIndex = 0;
        if(width >= sWidth[0]    && width < sWidth[1])    widthIndex = 1;
        if(width >= mWidth[0]    && width < mWidth[1])    widthIndex = 2;
        if(width >= lWidth[0]    && width < lWidth[1])    widthIndex = 3;
        if(width >= xlWidth[0]   && width < xlWidth[1])   widthIndex = 4;
        if(width >= xxlWidth[0]  && width < xxlWidth[1])  widthIndex = 5;
        if(width >= xxlWidth[0]  && width < xxlWidth[1])  widthIndex = 5;
        if(width >= xxxlWidth[0] && width < xxxlWidth[1]) widthIndex = 6;
        value = (() => {
            if(widthIndex === 0) return values[0];
            for(let i = widthIndex; i > 0; i--) {
                if(values[i] !== undefined) return values[i];
            }
        })()
    } else {
        let heightIndex = 0;
        const xsHeight   = [480, 550];
        const sHeight    = [550, 640];
        const mHeight    = [640, 667];
        const lHeight    = [667, 896];
        const xlHeight   = [896, 1080];
        const xxlHeight  = [1080, 3000];
        if(height >= xsHeight[0]   && height < xsHeight[1])   heightIndex = 0;
        if(height >= sHeight[0]    && height < sHeight[1])    heightIndex = 1;
        if(height >= mHeight[0]    && height < mHeight[1])    heightIndex = 2;
        if(height >= lHeight[0]    && height < lHeight[1])    heightIndex = 3;
        if(height >= xlHeight[0]   && height < xlHeight[1])   heightIndex = 4;
        if(height >= xxlHeight[0]  && height < xxlHeight[1])  heightIndex = 5;
        if(height >= xxlHeight[0]  && height < xxlHeight[1])  heightIndex = 5;
        value = (() => {
            if(heightIndex === 0) return values[0];
            for(let i = heightIndex; i > 0; i--) {
                if(values[i] !== undefined) return values[i];
            }
        })()
    }
    return value;
}

export function getJustChanges(next, actual, whiteList=[]) {
    let diff = {};
    for(let key in next) {
        let newValue = next[key];
        let currentValue = actual[key];
        if((Array.isArray(newValue) && Array.isArray(currentValue) && !areArraysTheSame(newValue, currentValue)) 
            || newValue !== currentValue 
            || whiteList.includes(key)) {
            diff[key] = newValue;
        }
    }
    return diff;
}

function areArraysTheSame(a, b) {
    const aSorted = [...a].sort();
    const bSorted = [...b].sort();
    return JSON.stringify(aSorted) === JSON.stringify(bSorted);
}

export function lightOrDark(color) {
    const hsp = getColorHsp(color)
    return { isLight: hsp>127.5, hsp }
}

export function getColorHsp(color) {
    var r, g, b, hsp;
    if (color.match(/^rgb/)) {
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);        
        r = color[1];
        g = color[2];
        b = color[3];
    } 
    else {
        color = +("0x" + color.slice(1).replace( 
        color.length < 5 && /./g, '$&$&'));
        r = color >> 16;
        g = color >> 8 & 255;
        b = color & 255;
    }
    
    hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    );
    return hsp;
}

export function generateRandomKey() {
    return Math.random().toString(36).slice(-8);
}

function _createBooksFilterFn(pattern) {
    return createFilter(pattern, ['title', 'author', 'note', 'collection'], { caseSensitive: false, normalize: true });
}

export function createShelveOrListFilter(pattern) {
    return createFilter(pattern, ['name'], { caseSensitive: false, normalize: true });
}

export function filterGroupBooks(pattern, filter, books) {
    if(filter == 'all' && !pattern) return books;
    let filteredBooks = [...books];
    if(filter !== 'all') {
        filteredBooks = books.filter(book => {
            return book.keywordIds.includes(filter);
        })
    }
    if(pattern) {
        const filterFn = createFilter(pattern, ['title', 'author'], { caseSensitive: false, normalize: true });
        filteredBooks = filteredBooks.filter(filterFn);
    }
    return filteredBooks;
}
export function getShortUrl(type, shortId) {
    if(!shortId) return;
    switch(type) {
        case 'list':
            return `${DOMAIN}/l/${shortId}`;
        case 'shelf':
            return `${DOMAIN}/s/${shortId}`;
        case 'book':
            return `${DOMAIN}/b/${shortId}`;
        case 'user':
            return `${DOMAIN}/u/${shortId}`;
        case 'group':
            return `${DOMAIN}/g/${shortId}`;
        case 'invite':
            return `${DOMAIN}/i/${shortId}`;
        case 'groupCollection':
            return `${DOMAIN}/c/${shortId}`;    
        default: 
            return;
    }
}

export async function share({ type, method, url, name, isOwner, t}) { 
    const details = getShareDetails(method, type, name, url, isOwner, t);
    try {
        switch(method) {
            case 'webShare':
                Sharing.shareAsync(url);
                break;
            case 'twitter':
                twitterShare(details.text, url, 550, 420, 'myblio')
                break
            case 'facebook':
                fbShare(url, 520, 350)
                break
            case 'email':
                MailComposer.composeAsync({ 
                    subject: details.subject, 
                    body: details.body 
                })
                break;
            case 'native': 
                await Share.share({
                    message: details.message,
                })
        }
    } catch(err) {
        __DEV__ && console.error(err);
    }
}

function getShareDetails(method, type, name, url, isOwner, t) {
    const viewer = isOwner ? 'owner' : 'user';
    switch(method) {
        case 'twitter':
            return { text: t(`sharing.${type}.twitter.${viewer}`, { name }) };
        case 'email':
            return {
                subject: t(`sharing.${type}.email.${viewer}.subject`, { name }),
                body: t(`sharing.${type}.email.${viewer}.body`, { url })
            }
        case 'native':
            return {
                message: t(`sharing.${type}.native.${viewer}`, { url, name })
            }
        default:
            return {}
    }
}

export function canGoBackInModal(navigation) {
    if(isInModal(navigation) && navigation.getState().index > 0 && navigation.canGoBack()) return true;
    return false;
}

export function capitalizeWord(word) {
    if(!word) return word;
    return word[0].toUpperCase() + word.substring(1);
}

export function getAverageColorFromBlurhash(blurhash) {
    const [r, g, b] = getAverageColor(blurhash)
    const rgb = `rgb(${r}, ${g}, ${b})`;
    const { isLight, hsp } = lightOrDark(rgb);
    const opacity = interpolate(hsp, [0, 200], [0,1], Extrapolate.CLAMP);
    const rgba = `rgba(${r}, ${g}, ${b}, ${opacity})`;
    return {
        rgb,
        hsp,
        rgba,
        isLight,
        opacity
    }
}

export function getMetrics() {
    const updateId = Updates.updateId;
    const appVersion = Constants?.manifest?.version || Constants?.expoConfig?.version || Application?.nativeApplicationVersion;
    const revisionId = (() => {
        let id = appVersion;
        if(isWeb() && window?.WEB_APP_VERSION) {
            id += `-${window.WEB_APP_VERSION}`;
        } else if(isNative() && updateId) {
            id += `-${updateId.split('-').pop()}`;
        }
        return id;
    })()
    const channel = Updates.channel || '';
    let sdkVersion = Constants?.manifest?.sdkVersion || Constants?.expoConfig?.sdkVersion || Constants?.sdkVersion;
    if(!sdkVersion && isNative()) sdkVersion = dependencies.expo.replace(/\^|\~/g, '');
    return {
        appVersion,
        channel,
        updateId,
        revisionId,
        osVersion: Platform.Version,
        sdkVersion

    }
}

export function getActiveScreenFromNavState(state) {
    let currentState = state;
    let activeScreenParams = {};
    let path = [];
    while(currentState) {
        const currentStateActiveRoute = currentState?.routes[currentState?.index];
        const nextState = currentStateActiveRoute?.state;
        path.push(currentStateActiveRoute?.name);
        if(nextState) {
            currentState = nextState;
        } else {
            if(currentStateActiveRoute?.params) {
                activeScreenParams = currentStateActiveRoute?.params;
            }
            currentState = undefined;
        }
    }
    return {
        path,
        activeScreen: path[path.length-1],
        activeScreenParams
    }
}

export function formatNavigationEventForLogging({ path, activeScreen, activeScreenParams }) {
    const params = {...activeScreenParams};
    delete params.photoTaken;
    delete params.contacts;
    return {
        path,
        type: 'navigation',
        value: activeScreen,
        params
    }
}

export function getRouteDetailsFromStateFromPath(stateFromPath) {
    let routeName;
    let routeParams = {};
    if(stateFromPath?.routes[0].state) {
        let state = stateFromPath?.routes[0].state;
        while(state) {
            if(state?.routes[0].state) {
                state = state?.routes[0].state
            } else {
                routeName = state?.routes[0].name;
                routeParams = state?.routes[0].params;
                state = undefined;
            }
        }
    }
    return {
        routeName,
        routeParams
    }
}

export async function handleUnreadNotifsDone(json) {
    if(json?.success && isNative()) {
        const { unreadNotifs } = json;
        let badgeCount = 0;
        badgeCount += unreadNotifs.lends.length;
        badgeCount += unreadNotifs.borrowRequestsSent.length;
        badgeCount += unreadNotifs.borrowRequestsAccepted.length;
        badgeCount += unreadNotifs.followRequests.length;
        badgeCount += unreadNotifs.groupInvitations.length;
        badgeCount += unreadNotifs.groupRequests.length;
        badgeCount += unreadNotifs.lendReturnRequests.length;
        for(let messageIds of Object.values(unreadNotifs.discussions)) {
            badgeCount += messageIds;
        }
        await Notifications.setBadgeCountAsync(parseInt(badgeCount));
    }
}

export function hexToRgba(hex, alpha) {
    return Color(hex).alpha(alpha).rgb().toString();
}

export function bookFilterIsEmpty(filters) {
    let isEmpty = true;
    for(let value of Object.values(filters)) {
        if(value?.length || (!Array.isArray(value) && value !== null)) return false;
    }
    return isEmpty;
}

export function getBookFilterCount(filters) {
    let count = 0;
    for(let value of Object.values(filters)) {
        if(value?.length || (!Array.isArray(value) && value !== null)) count++;
    }
    return count;
}

export function filterBooks(books, filters, lending) {
    const matched = [];
    for(let book of books) {
        let matchesFilter = true;
        if(!bookFilterIsEmpty(filters)) {
            if(filters.readingStatus !== null && 
               filters.readingStatus !== book.readingStatus) {
                matchesFilter = false;
            }
            if(filters.possessed !== null && 
                filters.possessed !== book.possessed) {
                matchesFilter = false;
            }
            if(filters.lending !== null) {
                let isLending = false;
                for(let lend of lending) {
                    if(lend.bookId == book.id && lend.returnedDatetime == null) {
                        isLending = true;
                        break;
                    }
                }
                if(isLending != filters.lending) {
                    matchesFilter = false;
                }
            }
            if(filters.keywords.length) {
                for(let keywordId of filters.keywords) {
                    if(!book.keywordIds.includes(keywordId)) {
                        matchesFilter = false;
                        break;
                    }
                }
            }
        }
        matchesFilter && matched.push(book);
    }
    return matched;
}

export function matchBooksByPatternAndFilters(books, pattern, filters, lending) {
    const booksMatchedPattern = books.filter(_createBooksFilterFn(pattern));
    const filteredBooks = filterBooks(booksMatchedPattern, filters, lending);
    return {
        booksMatchedPattern,
        filteredBooks
    }
}

export function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
}

export function getBookFilterCountDetail(filters, booksMatchedPattern, keywordIds, lending) {
    let booksMatchedPatternUniqIds = uniq(booksMatchedPattern.map(({id}) => id));
    const uniqBooksMatchedPattern = booksMatchedPattern.filter(book => { 
        const idNotYetProcessed = booksMatchedPatternUniqIds.includes(book.id); 
        booksMatchedPatternUniqIds = booksMatchedPatternUniqIds.filter(id => id !== book.id); 
        return idNotYetProcessed;
    })
    const filteredBooks = filterBooks(uniqBooksMatchedPattern, filters, lending);
    const filterCounts = {
        readingStatus: READING_STATUSES.reduce((a, v) => ({ ...a, [v]: 0}), {}),
        keywords: keywordIds.reduce((a, v) => ({ ...a, [v]: 0}), {}),
        possessed: { false: 0, true: 0 },
        lending: { false: 0, true: 0 },
        total: filteredBooks.length
    };
    for(let book of filteredBooks) {
        filterCounts.readingStatus[book.readingStatus]++;
        for(let keywordId of book.keywordIds) {
            filterCounts.keywords[keywordId]++;
        }
        filterCounts.possessed[book.possessed]++;
        let isLending = false;
        for(let lend of lending) {
            if(lend.bookId == book.id && lend.returnedDatetime == null) {
                isLending = true;
                break;
            }
        }
        filterCounts.lending[isLending]++;
    }
    if(filters.possessed !== null) {
        const { alternativeValue, filteredBooksFromAlternativeValue } = getFilteredBooksFromAlternativeValue('possessed', !filters.possessed, uniqBooksMatchedPattern, filters, lending);
        filterCounts.possessed[alternativeValue] = filteredBooksFromAlternativeValue.filter(({ possessed }) => possessed === alternativeValue).length;
    }
    if(filters.lending !== null) {
        const { alternativeValue, filteredBooksFromAlternativeValue } = getFilteredBooksFromAlternativeValue('lending', !filters.lending, uniqBooksMatchedPattern, filters, lending);
        filterCounts.lending[alternativeValue] = filteredBooksFromAlternativeValue.filter(book => !!lending.find(({ bookId }) => bookId === book.id) === alternativeValue).length;
    }
    if(filters.readingState !== null) {
        for(const readingStatus of READING_STATUSES.filter(status => status !== filters.readingState)) {
            const { alternativeValue, filteredBooksFromAlternativeValue } = getFilteredBooksFromAlternativeValue('readingStatus',readingStatus, uniqBooksMatchedPattern, filters, lending);
            filterCounts.readingStatus[alternativeValue] = filteredBooksFromAlternativeValue.filter(book => book.readingStatus === alternativeValue).length;
        }
    }
    return filterCounts;
}

function getFilteredBooksFromAlternativeValue(filterKey, alternativeValue, booksMatchedPattern, filters, lending) {
    const alternativeValueFilters = { ...deepCopy(filters), [filterKey]: alternativeValue };
    const filteredBooksFromAlternativeValue = filterBooks(booksMatchedPattern, alternativeValueFilters, lending);
    return {
        alternativeValue,
        filteredBooksFromAlternativeValue
    }
}

export function noResultsForSearchShelves(...args) {
    return _noResultForSearch(...args);
}

export function noResultsForSearchLists(...args) {
    return _noResultForSearch(...args);
}

function _noResultForSearch(filteredBooks, hasSearch, matchesContainer=false) {
    let hasResults = false;
    for(let [key, value] of Object.entries(filteredBooks)) {
        if(value.length) {
            hasResults = true;
            break;
        }
    }
    return !hasResults && hasSearch && !matchesContainer;
}

export function debugMemo(prevProps, nextProps) {
    console.log('------------------------------------------')
    const prev = [];
    const next = [];
    for(let [key, value] of Object.entries(prevProps)) {
        prev.push({ key, value});
    }
    for(let [key, value] of Object.entries(nextProps)) {
        next.push({ key, value});
    }
    let propHasChanged = false;
    for(let i = 0; i < prev.length; i++) {
        if(prev[i].value !== next[i].value) propHasChanged = true;
        console.log(
            prev[i].key, 
            'Is different?', 
            prev[i].value !== next[i].value, 
            prev[i].value, next[i].value
        );
    }
    if(propHasChanged) {
        console.log('🚨 Prop has changed');
    } else {
        console.log('👍 No props have changed');
    }
    return false;
}

export function darkenToContrast({rgb, targetContrast=6, desaturateValue=0}) {
    const color = Color(rgb);
    let darkenedColor = color;
    let contrast = color.contrast(color);
    let darkenValue = 0;
    while(contrast < targetContrast) {
        darkenedColor = color.darken(darkenValue).desaturate(desaturateValue);
        darkenValue += 0.05;
        contrast = darkenedColor.contrast(color);
    }
    return darkenedColor.rgb().toString();
}

export function normalizeToHsp({ rgb, hspTreshold=180, darkenHspOffset=20 }) {
    let color = Color(rgb);
    const hsp = getColorHsp(color.rgb().toString());
    if(hsp < hspTreshold) {
        color = lightenToHsp({ rgb, hspTreshold });
    } else if(hsp > hspTreshold) {
        color = darkenToHsp({ rgb, hspTreshold: hspTreshold + darkenHspOffset });
    }
    return color.rgb().toString();
}

function lightenToHsp({ rgb, hspTreshold }) {
    const color = Color(rgb);
    let lightenedColor = color;
    let hsp = getColorHsp(color.rgb().toString());
    let lightenValue = 0;
    while(hsp < hspTreshold) {
        lightenedColor = color.lighten(lightenValue);
        lightenValue += 0.05;
        hsp = getColorHsp(lightenedColor.rgb().toString());
    }
    return lightenedColor;
}

function darkenToHsp({ rgb, hspTreshold }) {
    const color = Color(rgb);
    let darkenedColor = color;
    let hsp = getColorHsp(color.rgb().toString());
    let darkenValue = 0;
    while(hsp > hspTreshold) {
        darkenedColor = color.darken(darkenValue);
        darkenValue += 0.05;
        hsp = getColorHsp(darkenedColor.rgb().toString());
    }
    return darkenedColor;
}

export function getPercentageSavingYearlyPlan(product) {
    const monthlyPrice = product?.prices?.length ? product.prices.find(({ recurring }) => recurring.interval === 'month') : product.default_price;
    const yearlyPrice = product?.prices?.length ? product.prices.find(({ recurring }) => recurring.interval === 'year') : product.default_price;
    if(monthlyPrice?.unit_amount && yearlyPrice.recurring.interval === 'year') {
        const yearlyPricePaidMonthly = monthlyPrice.unit_amount * 12;
        return Math.floor(((yearlyPricePaidMonthly - yearlyPrice.unit_amount) / yearlyPricePaidMonthly) * 100);
    }
    return;
}

export function getCanonicalEchelon(account) {
    if(account?.echelon === 'legacy') return 'basic';
    return account.echelon
};